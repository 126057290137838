import React, { FC, useEffect, useRef, useState } from 'react';
import classes from './RobotEdit.module.css';
import Tooltip from 'react-tooltip-lite';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as ICAL from 'ical';
import { selectAccessHoursEvents } from '../../selectors/accessHoursSelector';

interface RobotAccessHoursVisualsProps {
	robotId: string;
}

const RobotAccessHoursVisuals: FC<RobotAccessHoursVisualsProps> = props => {
	const { robotId } = props;
	const AccessHoursSelector = useSelector(selectAccessHoursEvents);

	//TODO: Multi event support
	const accessHoursEventString = AccessHoursSelector.accessHoursEvents[robotId];

	const [recurrenceDays, setRecurrenceDays] = useState<string[]>([]);
	const [eventsMap, setEventsMap] = useState<{ [key: string]: { start: Date; end: Date }[] }>({});
	const startTimePercent = useRef<number>(0.0);

	useEffect(() => {
		if (!accessHoursEventString) {
			setRecurrenceDays([]);
			return;
		}

		// Parse the ICS string
		const parseICS = (icsString: string) => {
			const event = ICAL.parseICS(icsString);
			const UUID: string = Object.keys(event)[0];

			//@ts-ignore
			const rruleString: string = event[UUID].rrule;
			if (!rruleString) return [];

			// Extract days from the rrule string
			const match = rruleString.match(/BYDAY=([^;]+)/);
			if (!match) return [];

			const daysMap: { [key: string]: string } = {
				MO: 'Monday',
				TU: 'Tuesday',
				WE: 'Wednesday',
				TH: 'Thursday',
				FR: 'Friday',
				SA: 'Saturday',
				SU: 'Sunday',
			};

			// Map to hold start and end timestamps for events on each day
			const newEventsMap: { [key: string]: { UUID: string; start: Date; end: Date }[] } = {
				Monday: [],
				Tuesday: [],
				Wednesday: [],
				Thursday: [],
				Friday: [],
				Saturday: [],
				Sunday: [],
			};

			const days = match[1].split(',');

			setRecurrenceDays(days.map(day => daysMap[day]));

			//@ts-ignore
			let dtstart = new Date(event[UUID].start);
			//@ts-ignore
			let dtend = new Date(event[UUID].end);

			// Populate eventsMap considering rollover
			days.forEach(day => {
				const dayName = daysMap[day];
				let startDay = new Date(dtstart);
				let endDay = new Date(dtend);

				// Correct day offsets based on rrule
				startDay.setDate(
					startDay.getDate() + (daysMap[day] === daysMap[startDay.getUTCDay()] ? 0 : 1)
				);
				endDay.setDate(
					endDay.getDate() + (daysMap[day] === daysMap[endDay.getUTCDay()] ? 0 : 1)
				);

				// Handle rollover
				if (endDay.getHours() < startDay.getHours()) {
					const nextDay = dailyIncrement(day);

					newEventsMap[dayName].push({
						UUID,
						start: dtstart,
						end: new Date(startDay.setHours(23, 59, 59, 999)), // till end of day
					});

					newEventsMap[daysMap[nextDay]].push({
						UUID,
						start: new Date(endDay.setHours(0, 0, 0, 0)), // from start of next day
						end: dtend,
					});
				} else {
					newEventsMap[dayName].push({
						UUID,
						start: dtstart,
						end: dtend,
					});
				}
			});

			setEventsMap(newEventsMap);
		};

		// Helper Function to get the next day in index
		const dailyIncrement = (currentDayId: any) => {
			const dayIndex = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
			return dayIndex[(dayIndex.indexOf(currentDayId.toUpperCase()) + 1) % dayIndex.length];
		};

		parseICS(accessHoursEventString);
	}, [accessHoursEventString]);

	const renderTimeSlotBar = (day: string) => {
		const events = eventsMap[day] || [];

		// Sort events by start time to ensure proper placement
		events.sort((a, b) => b.start.getTime() - a.start.getTime());

		let cumulativeEndPercent = 0;

		const eventContent = (
			<div className={classes.timeslotsBar}>
				<Typography variant="body1" className={classes.dayNameText}>
					{day}
				</Typography>
				{events.map((event, index) => {
					const dayLengthMinutes = 1440; // 24 hours * 60 minutes

					const eventStartMinutes =
						event.start.getHours() * 60 + event.start.getMinutes();
					const eventEndMinutes = event.end.getHours() * 60 + event.end.getMinutes();

					const startPercent = eventStartMinutes / dayLengthMinutes;
					let segmentWidthPercent =
						(eventEndMinutes - eventStartMinutes) / dayLengthMinutes;

					// Adjust margin left percent based on cumulative end percent
					const adjustedMarginLeftPercent = startPercent - cumulativeEndPercent;

					// Update cumulativeEndPercent to the current event's end as percentage of day
					cumulativeEndPercent += segmentWidthPercent;

					return (
						<div
							className={classes.timeslotBar}
							style={{
								marginLeft: `calc((100% - 120px) * ${adjustedMarginLeftPercent})`,
								maxWidth: `calc((100% - 120px) * ${segmentWidthPercent})`,
								minWidth: `calc((100% - 120px) * ${segmentWidthPercent})`,
								backgroundColor: '#56ae4d',
							}}
							key={index}
						>
							<Typography variant="body1" className={classes.whiteText}>
								{event.start.toTimeString().slice(0, 5)}
							</Typography>
							<Typography variant="body1" className={classes.whiteText}>
								{event.end.toTimeString().slice(0, 5)}
							</Typography>
						</div>
					);
				})}
			</div>
		);

		if (events.length === 0) {
			return eventContent;
		}

		return (
			<Tooltip
				content={events
					.map(
						event =>
							`${event.start
								.toTimeString()
								.slice(0, 5)} - ${event.end.toTimeString().slice(0, 5)}`
					)
					.join(', ')}
				direction="right"
			>
				{eventContent}
			</Tooltip>
		);
	};

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'top',
				justifyContent: 'space-between',
			}}
		>
			{renderTimeSlotBar('Monday')}
			{renderTimeSlotBar('Tuesday')}
			{renderTimeSlotBar('Wednesday')}
			{renderTimeSlotBar('Thursday')}
			{renderTimeSlotBar('Friday')}
			{renderTimeSlotBar('Saturday')}
			<div style={{ borderBottom: '1px solid #000000' }}>{renderTimeSlotBar('Sunday')}</div>
		</div>
	);
};

export default RobotAccessHoursVisuals;
