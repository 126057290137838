import React, { FC, useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonGrid,
	IonRow,
	IonImg,
} from '@ionic/react';
import {
	checkmarkCircle,
	closeCircle,
	checkmarkCircleOutline,
	reader,
	construct,
	trash,
	fitness,
	calendarSharp,
	rocket,
	power,
	reloadCircle,
	logoDropbox,
	alarm,
} from 'ionicons/icons';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import classes from './RobotEdit.module.css';
import Messages from './RobotEdit.messages';
import RobotButton from './RobotButton';
import RobotForm from '../RobotForm/RobotFormContainer';
import RobotSystem from '../RobotForm/RobotSystem';
import RobotNetwork from '../RobotForm/RobotNetwork';
import RobotHealth from '../RobotForm/RobotHealth';
import RobotAccessHoursVisuals from '../RobotEdit/RobotAccessHoursVisuals';
import { useTypedSelector } from '../../reducers';
import Tooltip from 'react-tooltip-lite';
import { publish } from '../../actions/publish';
import { b64EncodeUnicode } from '../../utils/encoding';
import { UVDMongoDevice } from '../../types/types';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { MenuItem, Box } from '@material-ui/core';
import AddGuestReservationModal from '../GuestReservationsModal/AddGuestReservationModal';
import AccessHoursModal from '../AccessHoursModal/AccessHoursModal';
import { gql, useMutation, useQuery } from '@apollo/client';
import GuestReservationsGrid from '../GuestReservationsGrid/GuestReservationsGrid';
import DeleteModal from '../DeleteModal/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { unsubscribe, subscribe } from 'redux-mqtt';
import { Typography } from '@material-ui/core';
import { selectAccessHoursEvents } from '../../selectors/accessHoursSelector';
import { deleteAccessHours } from '../../actions/accessHoursActions';
import EmptyGuestReservationLogo from '../../assets/Empty-guest-reservation-logo.svg';
const gobeTabs = ['details', 'system', 'network', 'reservations'];
const uvdTabs = ['health', 'details'];

const superusers = ['@blue-ocean-robotics', '@gobe-robots'];
const cmdTimeoutDuration = 10000;

const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));
interface RobotDetailsProps {
	robotId: string;
	onClose: any;
	device: UVDMongoDevice;
}

const RobotDetails: FC<RobotDetailsProps> = props => {
	const { robotId, onClose, device } = props;

	const [selectedTimeZone, setSelectedTimeZone] = useState('');

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);

	const AccessHoursSelector = useSelector(selectAccessHoursEvents);

	const [saved, setSaved] = useState(false);
	const [editable, setEditable] = useState(false);
	var operationInProgress = useRef(false);
	const [, forceRender] = useState(0);
	const newRobotManagerRequestId = () => Math.floor(Math.random() * 1e9); //9 digit unsigned integer
	const robotManagerRequestIdRef = useRef<number>(newRobotManagerRequestId());
	const [robotManagerStatus, setRobotManagerStatus] = useState<[string, string]>([
		'Robot Offline',
		'var(--ion-color-danger)',
	]);
	const firstUpdate = useRef(true);

	const username = useTypedSelector(state => state.accountState.user.username);
	const orgId = useTypedSelector(state => state.selectedOrganizationState.organization.orgId);
	const orgType = useTypedSelector(state => state.selectedOrganizationState.organization.orgType);
	const encodedUser = b64EncodeUnicode(username);
	const devices = useTypedSelector(state => state.deviceState.devicesByOrganizationId);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	const [selectedSegment, setSelectedSegment] = useState<string>(
		spinoutType === 'uvd' ? uvdTabs[0] : gobeTabs[0]
	);

	const [showAddGuestReservationModal, setShowAddGuestReservationModal] = useState(false);
	const [showDeleteGuestReservationModal, setShowDeleteGuestReservationModal] = useState(false);
	const [showAddAccessHoursModal, setShowAddAccessHoursModal] = useState(false);

	const deleteReservationRef = useRef({});

	const getGuestReservationsQuery = gql`
		query getGuestReservationsQuery($orgId: String!, $serialNumber: String!) {
			getGuestReservations(orgId: $orgId, serialNumber: $serialNumber) {
				orgId
				guest
				guestName
				serialNumber
				startingAt
				createdAt
				host
				hostName
				subject
				endingAt
				createdBy
			}
		}
	`;
	const {
		loading: getGuestReservationsLoading,
		error: getGuestReservationsError,
		data: getGuestReservationsData,
		refetch: refetchGuestReservationsData,
	} = useQuery(getGuestReservationsQuery, {
		variables: { orgId: selectedOrganizationId, serialNumber: device?.deviceId },
		fetchPolicy: 'no-cache',
	});

	const [guestReservations, setGuestReservations] = useState<any>([]);
	useEffect(() => {
		setGuestReservations(getGuestReservationsData?.getGuestReservations || []);
	}, [getGuestReservationsData, getGuestReservationsLoading]);

	const removeGuestReservationMutation = gql`
		mutation removeGuestReservationMutation(
			$orgId: String!
			$serialNumber: String!
			$guest: String!
			$startingAt: DateTime!
		) {
			removeGuestReservation(
				orgId: $orgId
				serialNumber: $serialNumber
				guest: $guest
				startingAt: $startingAt
			) {
				__typename
			}
		}
	`;
	const [removeGuestReservation] = useMutation(removeGuestReservationMutation, {
		onCompleted: data => {
			refetchGuestReservationsData();
		},
	});

	const resendCalendarInvitation = (guestReservation: any) => {
		publish(
			`microservice/${guestReservation?.orgId}/${b64EncodeUnicode(username)}/inviteUser`,
			{
				requestId: 'someId',
				data: {
					firstName: guestReservation?.guestName,
					lastName: '',
					username: guestReservation?.guest,
					orgId: guestReservation?.orgId,
					spinoutType,
					domainUrl: window.location.hostname,
					invitationType: 'calendarInvitation',
					guestReservation: {
						host: guestReservation?.host,
						hostName: guestReservation?.hostName,
						subject: guestReservation?.subject,
						startTime: guestReservation?.startingAt,
						endTime: guestReservation?.endingAt,
					},
				},
			}
		);
	};

	const formik = useFormik({
		initialValues: {
			...device,
		},
		validate: values => {
			let errors: any = {};
			if (!values.name || values.name == '') {
				errors.name = 'Device is required';
			} else {
				if (devices[orgId]) {
					let devicesByOrg = devices[orgId];
					Object.values(devicesByOrg).forEach((element: any) => {
						if (element.name === values.name && element.name !== device.name) {
							errors.name = 'Devices name must be unique';
						}
					});
				}
			}
			return errors;
		},
		onSubmit: (values: any) => {
			onEditRobotSubmit(values);
		},
	});
	const onCancelEdit = () => {
		setEditable(false);
		setSaved(false);
		formik.resetForm();
		formik.setErrors({ errors: {} });
	};

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);
		setEditable(false);
	};

	const onEditRobot = (data: any) => {
		setEditable(true);
	};

	const onDeleteRobot = () => {
		publish(`microservice/${orgId}/${b64EncodeUnicode(username)}/deleteDevice`, {
			requestId: 'deleteDeviceID',
			data: {
				deviceId: robotId,
			},
		});
		onClose();
	};

	const onEditRobotSubmit = (data: any) => {
		const name = data.name?.trim();
		if (data.name !== name) {
			data.name = name;
			formik.setFieldValue('name', data.name);
		}

		const updateParams: any = {
			name: data.name,
			location: data.location,
			timeZone: selectedTimeZone || data.timeZone,
		};

		if (
			updateParams.name !== device.name ||
			updateParams.location !== device.location ||
			updateParams.timeZone !== device.timeZone
		) {
			publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceInfo`, {
				requestId: 'updateDeviceInfoId',
				data: {
					device_type: device.deviceType,
					device_id: device.deviceId,
					deviceGroupsIds: Array.isArray(data.deviceGroupsIds)
						? data.deviceGroupsIds
						: [data.deviceGroupsIds],
					name: updateParams.name,
					location: updateParams.location,
					time_zone: updateParams.timeZone,
					access_hours_ics_events: device.accessHoursEvents,
				},
			});
		}

		setEditable(false);
	};

	const onRebootRequest = () => {
		operationInProgress.current = true;
		publish(robotId + `/Reboot/Remote/Request`, {
			robotManagerRequestId: robotManagerRequestIdRef.current,
		});
		console.log('Reboot request sent with request id: ' + robotManagerRequestIdRef.current);
	};
	const onRestartRequest = () => {
		operationInProgress.current = true;
		publish(robotId + `/Restart/Remote/Request`, {
			robotManagerRequestId: robotManagerRequestIdRef.current,
		});
		console.log('Restart request sent with request id: ' + robotManagerRequestIdRef.current);
	};
	const onShutdownRequest = () => {
		operationInProgress.current = true;
		publish(robotId + `/Shutdown/Remote/Request`, {
			robotManagerRequestId: robotManagerRequestIdRef.current,
		});
		console.log('Shutdown request sent with request id: ' + robotManagerRequestIdRef.current);
	};
	const onStorageModeRequest = () => {
		operationInProgress.current = true;
		publish(robotId + `/StorageMode/Remote/Request`, {
			robotManagerRequestId: robotManagerRequestIdRef.current,
		});
		console.log(
			'StorageMode request sent with request id: ' + robotManagerRequestIdRef.current
		);
	};
	const firstRun = useRef(true);
	useEffect(() => {
		if (firstRun.current) {
			firstRun.current = false;
			return;
		}
		const timeout = setTimeout(() => {
			console.log('Teststatus: ', robotManagerStatus[0]); // remove this
			if (operationInProgress.current && robotManagerStatus[0] != 'Robot Offline') {
				setRobotManagerStatus(['Command timed out', 'var(--ion-color-danger)']);
				operationInProgress.current = false;
				robotManagerRequestIdRef.current = newRobotManagerRequestId();
				forceRender(n => n + 1);
			}
		}, cmdTimeoutDuration);
		return () => clearTimeout(timeout);
	}, [robotManagerRequestIdRef.current]);

	const dispatch = useDispatch();
	useEffect(() => {
		let topic = `${robotId}/Reboot/Remote/Response`;
		dispatch(
			subscribe(topic, ({ message }: any) => {
				console.log('Robot response: ', message);
				console.log('TEST: ', robotManagerRequestIdRef.current);
				if (message.request_id === robotManagerRequestIdRef.current) {
					operationInProgress.current = false;
					if (message.received && message.supported) {
						console.log('Reboot request received and supported');
						setRobotManagerStatus(['Reboot initiated', 'var(--ion-color-primary)']);
					} else {
						console.log('Reboot request received but not supported');
						setRobotManagerStatus(['Reboot failed', 'var(--ion-color-danger)']);
					}
					robotManagerRequestIdRef.current = newRobotManagerRequestId();
				}
			})
		);

		return () => {
			dispatch(unsubscribe(topic));
		};
	}, [robotId, dispatch]);
	useEffect(() => {
		let topic = `${robotId}/Restart/Remote/Response`;
		dispatch(
			subscribe(topic, ({ message }: any) => {
				console.log('Robot response: ', message);
				if (message.request_id === robotManagerRequestIdRef.current) {
					operationInProgress.current = false;
					if (message.received && message.supported) {
						console.log('Restart request received and supported');
						setRobotManagerStatus(['Restart initiated', 'var(--ion-color-primary)']);
					} else {
						console.log('Restart request received but not supported');
						setRobotManagerStatus(['Restart failed', 'var(--ion-color-danger)']);
					}
					robotManagerRequestIdRef.current = newRobotManagerRequestId();
				}
			})
		);

		return () => {
			dispatch(unsubscribe(topic));
		};
	}, [robotId, dispatch]);
	useEffect(() => {
		let topic = `${robotId}/Shutdown/Remote/Response`;
		dispatch(
			subscribe(topic, ({ message }: any) => {
				console.log('Robot response: ', message);
				if (message.request_id === robotManagerRequestIdRef.current) {
					operationInProgress.current = false;
					if (message.received && message.supported) {
						console.log('Shutdown request received and supported');
						setRobotManagerStatus(['Shutdown initiated', 'var(--ion-color-primary)']);
					} else {
						console.log('Shutdown request received but not supported');
						setRobotManagerStatus(['Shutdown failed', 'var(--ion-color-danger)']);
					}
					robotManagerRequestIdRef.current = newRobotManagerRequestId();
				}
			})
		);

		return () => {
			dispatch(unsubscribe(topic));
		};
	}, [robotId, dispatch]);
	useEffect(() => {
		let topic = `${robotId}/StorageMode/Remote/Response`;
		dispatch(
			subscribe(topic, ({ message }: any) => {
				console.log('Robot response: ', message);
				if (message.request_id === robotManagerRequestIdRef.current) {
					operationInProgress.current = false;
					if (message.received && message.supported) {
						console.log('StorageMode request received and supported');
						setRobotManagerStatus([
							'Storage mode initiated',
							'var(--ion-color-primary)',
						]);
					} else {
						console.log('StorageMode request received but not supported');
						setRobotManagerStatus(['Storage mode failed', 'var(--ion-color-danger)']);
					}
					robotManagerRequestIdRef.current = newRobotManagerRequestId();
				}
			})
		);

		return () => {
			dispatch(unsubscribe(topic));
		};
	}, [robotId, dispatch]);

	useEffect(() => {
		if (device.online !== undefined) {
			setRobotManagerStatus(['Robot Online', 'var(--ion-color-success)']);
		} else {
			setRobotManagerStatus(['Robot Offline', 'var(--ion-color-danger)']);
			operationInProgress.current = false;
		}
	}, [device.online]);

	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;

			publish(`microservice/${orgId}/${encodedUser}/getOrgDevices`, {
				requestId: 'someId',
				data: {
					orgId: orgId,
					encodedEmail: encodedUser,
				},
			});
			return;
		}

		publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceInfo`, {
			requestId: 'updateDeviceInfoId',
			data: {
				device_type: device.deviceType,
				device_id: device.deviceId,
				deviceGroupsIds: device.deviceGroupsIds,
				name: device.name,
				location: device.location,
				time_zone: device.timeZone,
				access_hours_ics_events: AccessHoursSelector.accessHoursEvents[robotId]
					? [AccessHoursSelector.accessHoursEvents[robotId]]
					: [],
			},
		});
	}, [AccessHoursSelector.accessHoursEvents]);

	return (
		<>
			<div className={classes.header}>
				<div className={classes.editBtnContainer}>
					<IonButton
						className={
							selectedSegment !== 'details'
								? classes.hidden
								: editable
								? classNames(classes.editBtn, classes.editable)
								: classes.editBtn
						}
						shape="round"
						onClick={onEditRobot}
						disabled={editable}
						data-cy="robot_details_edit_button"
					>
						<IonIcon src="./assets/img/edit.svg" />
					</IonButton>

					{device.online !== undefined && selectedSegment === 'system' && (
						<>
							{superusers.some(superuser =>
								username.toLowerCase().includes(superuser)
							) && (
								<RobotButton
									className={
										selectedSegment !== 'system'
											? classes.hidden
											: editable
											? classNames(classes.editBtn, classes.editable)
											: classes.editBtn
									}
									editable={editable}
									onClickFunc={() => {
										if (
											window.confirm(
												'Are you sure you want to reboot? This will take a few minutes'
											)
										) {
											onRebootRequest();
											return true;
										}
										return false;
									}}
									tooltipText="Reboot without powercycle (Superuser only)"
									icon={rocket}
									btnText="Reboot"
									operationInProgress={operationInProgress.current}
								/>
							)}

							<RobotButton
								className={
									selectedSegment !== 'system'
										? classes.hidden
										: editable
										? classNames(classes.editBtn, classes.editable)
										: classes.editBtn
								}
								editable={editable}
								onClickFunc={() => {
									if (
										window.confirm(
											'Are you sure you want to restart? This will take a few minutes'
										)
									) {
										onRestartRequest();
										return true;
									}
									return false;
								}}
								tooltipText="Restart the GoBe Robot"
								icon={reloadCircle}
								btnText="Restart"
								operationInProgress={operationInProgress.current}
							/>

							<RobotButton
								className={
									selectedSegment !== 'system'
										? classes.hidden
										: editable
										? classNames(classes.editBtn, classes.editable)
										: classes.editBtn
								}
								editable={editable}
								onClickFunc={() => {
									if (
										window.confirm(
											'Are you sure you want to shut down? Turning back on will require physical access to the robot!'
										)
									) {
										onShutdownRequest();
										return true;
									}
									return false;
								}}
								tooltipText="Shut down the GoBe Robot"
								icon={power}
								btnText="Shut down"
								operationInProgress={operationInProgress.current}
							/>

							<RobotButton
								className={
									selectedSegment !== 'system'
										? classes.hidden
										: editable
										? classNames(classes.editBtn, classes.editable)
										: classes.editBtn
								}
								editable={editable}
								onClickFunc={() => {
									if (
										window.confirm(
											'Are you sure you want robot to enter storage mode? Turning back on will require physical access to the robot!'
										)
									) {
										onStorageModeRequest();
										return true;
									}
									return false;
								}}
								tooltipText="Put GoBe Robot into storage mode"
								icon={logoDropbox}
								btnText="Storage mode"
								operationInProgress={operationInProgress.current}
							/>
						</>
					)}
					{selectedSegment === 'system' && (
						<div
							style={{
								color: robotManagerStatus[1],
								fontSize: '14px',
								fontWeight: 'bold',
							}}
						>
							{robotManagerStatus[0]}
						</div>
					)}
					{orgType === 'bor' &&
						(!device.deviceGroupsIds || device.deviceGroupsIds.length == 0) && (
							<div className={classes.btnMargin}>
								<Tooltip
									direction="down"
									content={<FormattedMessage {...Messages.deleteTitle} />}
								>
									<IonButton
										className={
											selectedSegment !== 'details'
												? classes.hidden
												: editable
												? classNames(
														classes.editBtn,
														classes.editable,
														classes.trash
												  )
												: classNames(classes.editBtn, classes.trash)
										}
										shape="round"
										onClick={onDeleteRobot}
										disabled={editable}
									>
										<IonIcon icon={trash} />
									</IonButton>
								</Tooltip>
							</div>
						)}
					<div
						className={
							editable
								? classes.formBtns
								: classNames(classes.formBtns, classes.hidden)
						}
					>
						<IonIcon
							slot="end"
							size="large"
							icon={checkmarkCircle}
							onClick={() => formik.handleSubmit()}
							data-cy="robot_details_submit"
						/>
						<IonIcon
							slot="end"
							size="large"
							icon={closeCircle}
							onClick={onCancelEdit}
							data-cy="robot_details_cancel"
						/>
					</div>
					{selectedSegment == 'reservations' && (
						<RobotButton
							className="classes.editBtn"
							editable={true}
							onClickFunc={() => {
								setShowAddGuestReservationModal(true);
								return false;
							}}
							tooltipText="New reservation"
							icon={calendarSharp}
							btnText="New reservation"
							operationInProgress={false}
						/>
					)}
					{selectedSegment == 'accessHours' && (
						<>
							<RobotButton
								className="classes.editBtn"
								editable={true}
								onClickFunc={() => {
									if (!AccessHoursSelector.accessHoursEvents[robotId]) {
										setShowAddAccessHoursModal(true);
									} else {
										alert(
											'This beta version only supports one access hour entry at a time. Please delete the existing access hours before adding a new one.'
										);
									}

									return false;
								}}
								tooltipText="Add access hours"
								icon={alarm}
								btnText="Add access hours"
								operationInProgress={false}
							/>
							<div className={classes.btnMargin} />
							{AccessHoursSelector.accessHoursEvents[robotId] && (
								<RobotButton
									className="classes.editBtn"
									editable={true}
									onClickFunc={() => {
										if (
											window.confirm(
												'Are you sure you want to delete the access hours?'
											)
										) {
											dispatch(deleteAccessHours(robotId));
										}

										return false;
									}}
									tooltipText="Delete"
									icon={trash}
									btnText="Delete"
									operationInProgress={false}
								/>
							)}
						</>
					)}
					{!editable && saved ? (
						<>
							<IonIcon
								slot="end"
								size="small"
								color="primary"
								icon={checkmarkCircleOutline}
							/>
							<IonLabel className={classes.savedLb}>
								<FormattedMessage {...Messages.saved} />
							</IonLabel>
						</>
					) : null}
				</div>
				<IonSegment
					className={classes.tabContainer}
					mode="ios"
					onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
					value={selectedSegment}
				>
					{spinoutType === 'uvd' ? (
						<>
							<IonSegmentButton
								value="health"
								layout="icon-start"
								className={classes.healthBtn}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={fitness}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.health} />
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton
								value="details"
								layout="icon-start"
								className={classes.detailsBtn}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={reader}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.robotInfo} />
								</IonLabel>
							</IonSegmentButton>
						</>
					) : (
						<>
							<IonSegmentButton
								value="details"
								layout="icon-start"
								className={classes.detailsBtn}
								data-cy="robot_details_tab"
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={reader}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.details} />
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton
								value="system"
								layout="icon-start"
								className={classes.networkBtn}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={construct}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.system} />
								</IonLabel>
							</IonSegmentButton>
							{/* We may introduce this back in the future, dont remove */}
							{/* <IonSegmentButton
								value="network"
								layout="icon-start"
								className={classes.networkBtn}
							>
								<IonIcon className={classes.iconMargin} size="small" icon={globe} />
								<IonLabel>
									<FormattedMessage {...Messages.network} />
								</IonLabel>
							</IonSegmentButton> */}
							<IonSegmentButton
								value="reservations"
								layout="icon-start"
								className={classes.reservationsBtn}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={calendarSharp}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.reservations} />
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton
								value="accessHours"
								layout="icon-start"
								className={classes.accessHoursBtn}
							>
								<IonIcon className={classes.iconMargin} size="small" icon={alarm} />
								<IonLabel>
									<FormattedMessage {...Messages.accessHours} />
								</IonLabel>
							</IonSegmentButton>
						</>
					)}
				</IonSegment>
			</div>
			<IonGrid className={classes.segmentContent}>
				<IonRow className={selectedSegment !== 'details' ? classes.hidden : ''}>
					<RobotForm
						control={formik}
						device={device}
						deviceId={robotId}
						isEditable={editable}
						selectedTimeZone={setSelectedTimeZone}
					/>
				</IonRow>
				<IonRow className={selectedSegment !== 'system' ? classes.hidden : ''}>
					<RobotSystem deviceId={robotId} isEditable={editable} saved={saved} />
				</IonRow>
				<IonRow className={selectedSegment !== 'network' ? classes.hidden : ''}>
					<RobotNetwork deviceId={robotId} isEditable={editable} saved={saved} />
				</IonRow>
				<IonRow className={selectedSegment !== 'health' ? classes.hidden : ''}>
					<RobotHealth
						deviceId={robotId}
						isEditable={editable}
						saved={saved}
						device={device}
					/>
				</IonRow>
				<IonRow
					className={
						selectedSegment !== 'reservations'
							? classes.hidden
							: classes.reservationContainer
					}
				>
					<GuestReservationsGrid
						data={guestReservations}
						getGuestReservationsLoading={getGuestReservationsLoading}
						selectedDeviceId={device?.deviceId}
						moreContent={(item: any, onClosePopover: any) => {
							return (
								<>
									<StyledMenuItem
										onClick={() => {
											resendCalendarInvitation(item);
											onClosePopover();
										}}
									>
										<FormattedMessage {...Messages.resend} />
									</StyledMenuItem>
									<StyledMenuItem
										onClick={() => {
											deleteReservationRef.current = {
												orgId: item.orgId,
												serialNumber: item.serialNumber,
												guest: item.guest,
												startingAt: item.startingAt,
											};
											setShowDeleteGuestReservationModal(true);
											onClosePopover();
										}}
									>
										<FormattedMessage {...Messages.delete} />
									</StyledMenuItem>
								</>
							);
						}}
					/>
					<AddGuestReservationModal
						isOpen={showAddGuestReservationModal}
						onDismiss={() => setShowAddGuestReservationModal(false)}
						refetchGuestReservationsData={refetchGuestReservationsData}
						selectedDeviceId={device?.deviceId}
					/>
					<DeleteModal
						isOpen={showDeleteGuestReservationModal}
						title={<FormattedMessage {...Messages.deleteTitle} />}
						onConfirm={() => {
							removeGuestReservation({
								variables: { ...deleteReservationRef.current },
							});
							deleteReservationRef.current = {};
							setShowDeleteGuestReservationModal(false);
						}}
						onDismiss={() => {
							deleteReservationRef.current = {};
							setShowDeleteGuestReservationModal(false);
						}}
						itemName="Reservation"
					/>
				</IonRow>
				<IonRow
					className={
						selectedSegment !== 'accessHours'
							? classes.hidden
							: classes.accessHoursContainer
					}
				>
					{AccessHoursSelector.accessHoursEvents &&
						AccessHoursSelector.accessHoursEvents[robotId] && (
							<RobotAccessHoursVisuals robotId={robotId} />
						)}
					{(!AccessHoursSelector.accessHoursEvents ||
						!AccessHoursSelector.accessHoursEvents[robotId]) && (
						<IonRow className={classes.noData}>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								width="100%"
								flexDirection="column"
							>
								<IonImg src={EmptyGuestReservationLogo} />
								<IonLabel className={classes.noDataHint}>
									Access hours not configured, this robot is available at all
									times
								</IonLabel>
							</Box>
						</IonRow>
					)}

					<AccessHoursModal
						isOpen={showAddAccessHoursModal}
						onDismiss={() => {
							setShowAddAccessHoursModal(false);
						}}
						selectedDeviceId={device?.deviceId}
						robotId={robotId}
					/>
				</IonRow>
			</IonGrid>
		</>
	);
};

export default RobotDetails;
