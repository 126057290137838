import classNames from 'classnames';
import React from 'react';
import { useTypedSelector } from '../../../../../../../reducers';
import { Device } from '../../../../../../../types/types';
import { getStateEntityByOrgId } from '../../../../../../../utils/conformState';
import { equalityFnc } from '../../../../utils/deviceStateConverter';
import { useDeviceCurrentState } from '../../../../utils/statusConverter';
import { BatteryColumn } from './components/BatteryColumn';
import { FavoriteColumn } from './components/FavoriteColumn';
import { NameColumn } from './components/NameColumn/NameColumn';
import { StatusColumn } from './components/StatusColumn/StatusColumn';
import { useSelector } from 'react-redux';
import { selectAccessHoursEvents } from '../../../../../../../selectors/accessHoursSelector';
import * as ICAL from 'ical';
import './index.scss';

interface Props {
	deviceId: number | string;
	orgId: number | string;
	deviceGroupsNames?: string | null;
	onClickStartSession: (
		device: Device,
		reservationStartTime?: string,
		reservationEndDate?: string,
		isMyReservation?: boolean
	) => void;
	isCalling: boolean;
	publishFavouriteDevice: any;
}

export const LaunchRow = ({
	deviceId,
	orgId,
	deviceGroupsNames,
	onClickStartSession,
	isCalling,
	publishFavouriteDevice,
}: Props) => {
	const device = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'accountState', {
				entityId: deviceId as string,
				orgId: orgId as string,
				propertyOptions: [
					'deviceId',
					'serialNumber',
					'online',
					'status',
					'battery',
					'currentState',
					'name',
					'battery',
					'location',
					'guestReservation',
					'isPermanent',
				],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const accountState = useTypedSelector(state => state.accountState);
	const isFavorite = accountState.user.favouriteDevice?.includes(device.serialNumber) || false;

	const {
		canCall,
		deviceStatus,
		othersNextReservation,
		myNextReservation,
		nextReservation,
	} = useDeviceCurrentState(device, isCalling);
	console.log('device battery', device?.battery);

	const accessHoursEvents = useSelector(selectAccessHoursEvents);

	let AccessHoursNotBlocking = true;
	let formattedStartTime = '';
	let formattedEndTime = '';
	let days: string[] = [];

	if (Object.keys(accessHoursEvents.accessHoursEvents).includes(device.serialNumber)) {
		const event = ICAL.parseICS(accessHoursEvents.accessHoursEvents[device.serialNumber]);

		const UUID: string = Object.keys(event)[0];

		//@ts-ignore
		const rruleString: string = event[UUID].rrule;

		const match = rruleString.match(/BYDAY=([^;]+)/); // Extract days from the rrule string
		if (!match) {
			AccessHoursNotBlocking = false;
		} else {
			//Verify if current day is one where access is available
			days = match[1].split(',');
			const daysArray = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
			const currentDayIndex = new Date().getDay();
			const currentDay = daysArray[currentDayIndex - 1];

			if (!days.includes(currentDay)) {
				AccessHoursNotBlocking = false;
			}
			let nowHours = new Date().getHours();
			let nowMinutes = new Date().getMinutes();
			let nowDate = new Date();
			nowDate.setHours(nowHours, nowMinutes, 0, 0);

			let endHours = event[UUID]['end']!.getHours();
			let endMinutes = event[UUID]['end']!.getMinutes();
			let endDate = new Date();
			endDate.setHours(endHours, endMinutes, 0, 0);

			let startHours = event[UUID]['start']!.getHours();
			let startMinutes = event[UUID]['start']!.getMinutes();
			let startDate = new Date();
			startDate.setHours(startHours, startMinutes, 0, 0);

			if (
				!(endDate.getTime() > nowDate.getTime() && startDate.getTime() < nowDate.getTime())
			) {
				AccessHoursNotBlocking = false;
			}

			// Format times as strings for display
			const formatTime = (date: Date) => {
				return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			};

			formattedStartTime = formatTime(event[UUID]['start']!);
			formattedEndTime = formatTime(event[UUID]['end']!);
		}
	}

	//Check if access hours are blocking access to the robot. Access hours do not apply to reservations
	const isCallable =
		(canCall &&
			!Object.keys(accessHoursEvents.accessHoursEvents).includes(device.serialNumber)) ||
		(Object.keys(accessHoursEvents.accessHoursEvents).includes(device.serialNumber) &&
			AccessHoursNotBlocking) ||
		!!nextReservation;

	return (
		<div className={classNames('rosterListRow oneGoBeListContainer')}>
			<FavoriteColumn
				isFavorite={isFavorite}
				onClick={() => publishFavouriteDevice(device as Device)}
			/>
			<NameColumn
				canCall={canCall}
				onClickStartSession={() => {
					if (isCallable) {
						onClickStartSession(
							device as Device,
							myNextReservation,
							othersNextReservation
						);
					} else {
						alert(
							`This robot may only be used between ${formattedStartTime} and ${formattedEndTime} on days ${days.join(
								', '
							)} (Your local time)\nContact your robot administrator for more information.`
						);
					}
				}}
				nextReservation={nextReservation}
				deviceStatus={!isCallable ? 'unavailable' : deviceStatus}
				isCalling={isCalling}
				deviceName={device.name}
				isPermanentDevice={device.isPermanent}
			/>
			<StatusColumn
				nextReservation={nextReservation}
				othersNextReservation={othersNextReservation}
				myNextReservation={myNextReservation}
				deviceStatus={!isCallable ? 'unavailable' : deviceStatus}
				isCalling={isCalling}
				isPermanentDevice={device.isPermanent}
				deviceSerial={device.serialNumber}
			/>
			<BatteryColumn
				deviceBattery={
					device?.battery ?? (device?.status ? JSON.parse(device?.status).battery : {})
				}
			/>
			<div className="rosterListRowCell">
				<span className="bodyText">{device.location || 'Location unknown'}</span>
			</div>
			<div className="rosterListRowCell">
				<span className="bodyText">{deviceGroupsNames}</span>
			</div>
		</div>
	);
};
