import React, { FC, useState } from 'react';
import { IonGrid, IonRow, IonList, IonItem, IonLabel, IonListHeader } from '@ionic/react';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './RobotForm.messages';

import classes from './RobotForm.module.css';
import { mmmDDYYYYFormat } from '../../utils/formatTime';
import { useTypedSelector } from '../../reducers';
import { getStateEntityByOrgId, equalityFnc } from '../../utils/conformState';

interface RobotFormProps {
	deviceId: any;
	isEditable?: boolean;
}

const RobotSystem: FC<RobotFormProps> = props => {
	const { isEditable, deviceId } = props;

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const device = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: deviceId,
				orgId: selectedOrganizationId,
				propertyOptions: ['deviceId', 'softwareVersion', 'dateAdded'],
			}),
		(left, right) => equalityFnc(left, right)
	);
	const [changingDevice] = useState(JSON.parse(JSON.stringify(device)));

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList>
					<IonListHeader>
						<IonLabel>
							<FormattedMessage {...Messages.robotSystem} />
						</IonLabel>
					</IonListHeader>

					<IonItem disabled>
						<IonLabel position="fixed" className={classes.formLb}>
							<FormattedMessage {...Messages.serialNumber} />:
						</IonLabel>
						<IonLabel className={classes.inputLb}>{changingDevice.deviceId}</IonLabel>
					</IonItem>

					<IonItem disabled={!isEditable}>
						<IonLabel position="fixed" className={classes.formLb}>
							<FormattedMessage {...Messages.dateAdded} />
						</IonLabel>

						<IonLabel className={classes.inputLb}>
							{mmmDDYYYYFormat(changingDevice.dateAdded || 'Jan 1, 1970')}
						</IonLabel>
					</IonItem>
				</IonList>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(RobotSystem, 'RobotSystem'));
