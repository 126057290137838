const initialState: any = {
	accessHoursEvents: {},
};

const accessHoursReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case 'ADD_ACCESS_HOURS':
			return {
				...state,
				accessHoursEvents: { ...state.accessHoursEvents, ...action.payload },
			};
		case 'DELETE_ACCESS_HOURS':
			const robot: string = action.payload;
			let accessHoursEvents = state.accessHoursEvents;
			delete accessHoursEvents[robot];
			return {
				...state,
				accessHoursEvents: { ...accessHoursEvents },
			};
		case 'DELETE_ALL_ACCESS_HOURS':
			return {
				...state,
				accessHoursEvents: {},
			};
		default:
			return state;
	}
};

export default accessHoursReducer;
