import classNames from 'classnames';
import React from 'react';
import { Device } from '../../../../../../../../../../types/types';
import { useDeviceCurrentState } from '../../../../../../../utils/statusConverter';
import '../../../index.scss';
import { DefaultStatus } from './DefaultStatus';
import { ReservationStatus } from './ReservationStatus';
import { useSelector } from 'react-redux';
import { selectAccessHoursEvents } from '../../../../../../../../../../selectors/accessHoursSelector';
import * as ICAL from 'ical';

interface Props {
	device: Partial<Device>;
	isCalling: boolean;
	onClickStartSession: (
		device: Device,
		reservationStartTime?: string,
		reservationEndTime?: string,
		isMyReservation?: boolean
	) => void;
}

export const Status = ({ device, isCalling, onClickStartSession }: Props) => {
	const {
		canCall,
		deviceStatus,
		nextReservation,
		myNextReservation,
		othersNextReservation,
	} = useDeviceCurrentState(device, isCalling);

	const accessHoursEvents = useSelector(selectAccessHoursEvents);

	let AccessHoursNotBlocking = true;
	let formattedStartTime = '';
	let formattedEndTime = '';
	let days: string[] = [];

	if (Object.keys(accessHoursEvents.accessHoursEvents).includes(device.serialNumber)) {
		const event = ICAL.parseICS(accessHoursEvents.accessHoursEvents[device.serialNumber]);

		const UUID: string = Object.keys(event)[0];

		//@ts-ignore
		const rruleString: string = event[UUID].rrule;

		const match = rruleString.match(/BYDAY=([^;]+)/); // Extract days from the rrule string
		if (!match) {
			AccessHoursNotBlocking = false;
		} else {
			//Verify if current day is one where access is available
			days = match[1].split(',');
			const daysArray = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
			const currentDayIndex = new Date().getDay();
			const currentDay = daysArray[currentDayIndex - 1];

			if (!days.includes(currentDay)) {
				AccessHoursNotBlocking = false;
			}
			let nowHours = new Date().getHours();
			let nowMinutes = new Date().getMinutes();
			let nowDate = new Date();
			nowDate.setHours(nowHours, nowMinutes, 0, 0);

			let endHours = event[UUID]['end']!.getHours();
			let endMinutes = event[UUID]['end']!.getMinutes();
			let endDate = new Date();
			endDate.setHours(endHours, endMinutes, 0, 0);

			let startHours = event[UUID]['start']!.getHours();
			let startMinutes = event[UUID]['start']!.getMinutes();
			let startDate = new Date();
			startDate.setHours(startHours, startMinutes, 0, 0);

			if (
				!(endDate.getTime() > nowDate.getTime() && startDate.getTime() < nowDate.getTime())
			) {
				AccessHoursNotBlocking = false;
			}

			// Format times as strings for display
			const formatTime = (date: Date) => {
				return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			};

			formattedStartTime = formatTime(event[UUID]['start']!);
			formattedEndTime = formatTime(event[UUID]['end']!);
		}
	}

	//Check if access hours are blocking access to the robot. Access hours do not apply to reservations
	const isCallable =
		(canCall &&
			!Object.keys(accessHoursEvents.accessHoursEvents).includes(device.serialNumber)) ||
		(Object.keys(accessHoursEvents.accessHoursEvents).includes(device.serialNumber) &&
			AccessHoursNotBlocking) ||
		!!nextReservation;

	return (
		<div
			className={classNames('content', {
				pointerCursor: canCall,
			})}
			onClick={() => {
				if (isCallable) {
					onClickStartSession(device as Device, myNextReservation, othersNextReservation);
				} else {
					if (!AccessHoursNotBlocking) {
						alert(
							`This robot may only be used between ${formattedStartTime} and ${formattedEndTime} on days ${days.join(
								', '
							)} (Your local time)\nContact your robot administrator for more information.`
						);
					}
					return;
				}
			}}
		>
			<div className="statusContentContainer">
				{!!nextReservation ? (
					<ReservationStatus
						reservation={nextReservation}
						deviceStatus={deviceStatus}
						isPermanentDevice={device.isPermanent}
						isCalling={isCalling}
					/>
				) : (
					<DefaultStatus status={deviceStatus} isCalling={isCalling} />
				)}
			</div>
			<p className="bodyM textAlignCenter location">
				{device?.location ? device.location : 'Location unknown'}
			</p>
		</div>
	);
};
