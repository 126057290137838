import React, { FC } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';

import AccessHoursForm from './AccessHoursForm';

import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';

import { Box, Container, Typography } from '@material-ui/core';
import BasicModal from '../BasicModal/BasicModal';
import classes from './AccessHoursModal.module.css';

interface AccessHoursProps extends RouteComponentProps {
	isOpen: boolean;
	onDismiss: any;
	robotId: string;
}

const AccessHoursModal: FC<AccessHoursProps> = (props: any) => {
	const { robotId } = props;

	const onDismissModal = () => {
		props.onDismiss();
	};

	return (
		<BasicModal
			open={props.isOpen}
			onClose={onDismissModal}
			className={classes.accessHoursModal}
		>
			<Box
				sx={{
					padding: 30,
				}}
			>
				<Typography variant="h3" className={classes.header} align="center">
					Add access hours
				</Typography>
				<Container>
					<AccessHoursForm onCancel={props.onDismiss} robotId={robotId} />
				</Container>
			</Box>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(AccessHoursModal), 'AccessHoursModal'));
