import { MqttRouter } from 'mqtt-sub-router';
import { publish } from '../actions/publish';
import { SET_GET_OWN_ACCOUNT_INFO_TIMESTAMP } from '../actions/types';
import { subscribedDevices, subscribeToDevice, subscribeToOrganization } from '../providers/mqtt';
import { store } from '../store/store';
import { b64EncodeUnicode } from '../utils/encoding';
import { addAccessHours, deleteAllAccessHours } from '../actions/accessHoursActions';

const subscribeToOrganizations = () => {
	let wrappedClient = store.getState().mqttState.client;
	for (const id in store.getState().accountState.user.roles) {
		if (store.getState().organizationState.subscribedOrgs.indexOf(id) === -1) {
			subscribeToOrganization(wrappedClient, id);
		}
	}
};
const subscribeToDevices = () => {
	let devices = store.getState().accountState.user.devices;
	let wrappedClient = store.getState().mqttState.client;
	if (devices) {
		for (const deviceId in devices) {
			subscribeToDevice(wrappedClient, deviceId);
		}
	}
};
const handleSubscriptions = () => {
	subscribeToOrganizations();
	// Deprecated, subscribing to devices is handled by gb_op_module with pagination for efficiency reasons
	// subscribeToDevices();
};
const AccountRouter = new MqttRouter()
	.listen(
		'getOwnAccountInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			let user = store.getState().accountState.user;
			let wrappedClient = store.getState().mqttState.client;
			const spinoutType = store.getState().versionState.spinoutType;

			// Get own account timestamp
			store.dispatch({
				type: SET_GET_OWN_ACCOUNT_INFO_TIMESTAMP,
				payload: { getOwnAccountInfoTimestamp: +new Date() },
			});

			// TODO: fix the microservice to not supply roles with roleId set to empty string, this bug occurs when removing a user admin privelages for an organisation
			// Until ↑ is fixed, we need to filter out these corrupt data here instead
			if (data.roles && Object.keys(data.roles).length) {
				const filterredRoles = {};
				for (const id in data.roles) {
					if (data.roles[id].roleId !== '') filterredRoles[id] = data.roles[id];
				}
				data.roles = filterredRoles;
			}

			if (data.bookingToken) {
				localStorage.setItem('bookingFleetSharedJwtSecret', data.bookingToken);
			}

			store.dispatch({
				type: 'SET_ADMIN_ROLE',
				payload: {},
			});

			// if (data.jwt) {
			// 	store.dispatch({
			// 		type: SET_ACCOUNT_USER_JWT,
			// 		payload: { jwt: data.jwt },
			// 	});
			// }

			// Hide the loader in case of non admin user
			if (!data.roles || !Object.keys(data.roles).length || !data.orgs) {
				store.dispatch({
					type: `CHANGE_LOADER`,
					payload: { showLoader: false, location: 'AccountRouter' },
				});
			}

			publish(`microservice/${b64EncodeUnicode(user.username)}/getOrgStructure`, {
				requestId: `getOrgStructure-${user.username}`,
				data: {},
			});

			const selectedOrgId = store.getState().selectedOrganizationState.organization.orgId;

			for (const key in data) {
				if (key === 'language') {
					if (data[key] && data[key].length > 2) {
						data[key] = data[key].toLowerCase();
					} else {
						data[key] = 'english';
					}
				} else if (key === 'selectedOrganizationId') {
					if (!data[key]) {
						try {
							data[key] = Object.keys(data.orgs)[0];
						} catch (error) {
							console.log(error);
						}
					}
					if (data[key] === null || data[key] === undefined || data[key].length === 0)
						return;
				} else if (key === 'orgs') {
					store.dispatch({
						type: `UPDATE_ORGANIZATIONS`,
						payload: { organizations: data[key] },
					});
					continue;
				} else if (key === 'roles') {
					for (const id in data[key]) {
						if (!selectedOrgId) {
							publish(
								`microservice/${id}/${b64EncodeUnicode(user.username)}/getOrgInfo`,
								{
									requestId: `getOrgInfoId-${id}`,
									data: { spinoutType },
								}
							);
						}
					}
				}
				store.dispatch({
					type: `UPDATE_${key.toUpperCase()}`,
					payload: { [key]: data[key] },
				});
			}
			if (selectedOrgId) {
				publish(
					`microservice/${selectedOrgId}/${b64EncodeUnicode(user.username)}/getOrgInfo`,
					{
						requestId: `getOrgInfoId-${selectedOrgId}`,
						data: {},
					}
				);
			}

			// Resubscribe to lost subscribed devices
			Object.entries(subscribedDevices).forEach(([deviceId, subscribed]) => {
				if (!subscribed) {
					subscribeToDevice(wrappedClient, deviceId);
					subscribedDevices[deviceId] = true;
				}
			});
		}
	)
	.listen(
		'updateOwnInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			for (const key in data) {
				if (key === 'language') {
					if (data[key]) data[key] = data[key].toLowerCase();
					else data[key] = 'english';
				}
				if (
					key === 'country' ||
					key === 'firstName' ||
					key === 'language' ||
					key === 'lastName' ||
					key === 'location' ||
					key === 'profilePictureLink' ||
					key === 'selectedOrganizationId'
				)
					store.dispatch({
						type: `UPDATE_${key.toUpperCase()}`,
						payload: { [key]: data[key] },
					});
			}
		}
	)

	.listen(
		'addDeviceToFavourite/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			for (const key in data) {
				if (key === 'favouriteDevice')
					store.dispatch({
						type: `UPDATE_${key.toUpperCase()}`,
						payload: { [key]: data[key] },
					});
			}
		}
	)

	.listen(
		'updatePassword/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (status === 'success') {
				console.log('successfully changed the password');
			} else if (status === 'error') {
				console.log('error on changing password');
			}
		}
	)
	.listen(
		'passwordReset/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `PASSWORD_RESET_RESPONSE`,
				payload: { requestId, status },
			});
		}
	)
	.listen(
		'updateUserInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (data.username !== store.getState().accountState.user.username) return;

			for (const key in data) {
				if (
					key === 'country' ||
					key === 'firstName' ||
					key === 'language' ||
					key === 'lastName' ||
					key === 'location' ||
					key === 'profilePictureLink' ||
					key === 'selectedOrganizationId'
				)
					store.dispatch({
						type: `UPDATE_${key.toUpperCase()}`,
						payload: { [key]: data[key] },
					});
			}
		}
	)
	.listen(
		'getOrgUsers/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `FETCH_USERS_SUCCESS`,
				payload: { users: data },
			});
		}
	)
	.listen(
		'getOrgDevices/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			let wrappedClient = store.getState().mqttState.client;
			const accountDevices = store.getState().accountState.user.devices;
			store.dispatch({
				type: `FETCH_DEVICES_SUCCESS`,
				payload: { devices: data, accountDevices },
			});
			store.dispatch(deleteAllAccessHours());
			for(let device of data) {
				if(device.accessHoursIcsEvents) {
					for(let event of device.accessHoursIcsEvents) {
						store.dispatch(addAccessHours(device.deviceId, event));
					}
				}
			}
		}
	)
	.listen(
		'getDeviceGroups/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `FETCH_DEVICE_GROUPS_SUCCESS`,
				payload: data,
			});
			store.dispatch({
				type: `CHANGE_LOADER`,
				payload: { showLoader: false, location: 'AccountRouter' },
			});

			// Subscribe
			handleSubscriptions();
		}
	)
	.listen(
		'getOrgInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data || !data.orgId) {
				store.dispatch({
					type: 'ORGANIZATION_INFORMATION_DENIED',
					payload: {},
				});
				store.dispatch({
					type: `CHANGE_LOADER`,
					payload: { showLoader: false, location: 'AccountRouter' },
				});

				// Subscribe
				handleSubscriptions();
				return;
			}
			const spinoutType = store.getState().versionState.spinoutType;
			const encodedUser = b64EncodeUnicode(store.getState().accountState.user.username);
			const orgId = data.orgId;

			for (const key in data) {
				if (key === 'childOrgs') {
					if (data[key] && Object.keys(data[key]).length > 0) {
						for (const id in data[key]) {
							// make sure to only display the correct spinout type organizations
							if (
								data[key][id].spinoutType &&
								data[key][id].spinoutType !== spinoutType
							)
								delete data[key][id];
						}
						for (const id in data[key]) {
							if (!data[key][id].orgId) data[key][id].orgId = id;
						}
						store.dispatch({
							type: `FETCH_ORGANIZATION_CHILDREN`,
							payload: { children: Object.values(data[key]) },
						});

						store.dispatch({
							type: 'ORGANIZATION_CHILDREN_FETCHED',
							payload: {},
						});

						store.dispatch({
							type: 'ORGANIZATIONS_FETCHED',
							payload: {},
						});
					}
				}

				store.dispatch({
					type: `UPDATE_SEL_ORG_${key.toUpperCase()}`,
					payload: { [key]: data[key] },
				});
			}

			store.dispatch({
				type: 'ORGANIZATION_INFORMATION_FETCHED',
				payload: {},
			});

			store.dispatch({
				type: `FETCH_ORGANIZATIONS`,
				payload: { organizations: [data] },
			});

			publish(`microservice/${orgId}/${encodedUser}/getOrgUsers`, {
				requestId: 'someId',
			});

			publish(`microservice/${orgId}/${encodedUser}/getOrgDevices`, {
				requestId: 'someId',
				data: {
					orgId: orgId,
					encodedEmail: encodedUser,
				},
			});

			publish(`microservice/${orgId}/${encodedUser}/getUserGroups`, {
				requestId: 'someId',
			});

			publish(`microservice/${orgId}/${encodedUser}/getDeviceGroups`, {
				requestId: 'someId',
				data: {
					orgId: orgId,
					encodedEmail: encodedUser,
				},
			});

			publish(`microservice/${orgId}/${encodedUser}/getOrgSSOInfo`, {
				requestId: `getOrgSSOInfoId-${orgId}`,
				data: {},
			});
		}
	)
	.listen(
		'getUserGroups/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `FETCH_USER_GROUPS_SUCCESS`,
				payload: data,
			});
		}
	)
	.listen(
		'createUserGroup/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `SET_USER_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on create user group');
			}
		}
	)
	.listen(
		'updateUserGroupInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_USER_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on update user group info');
			}
		}
	)
	.listen(
		'updateUserGroupUsers/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_USER_GROUP_USERS`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on update user group users');
			}
		}
	)
	.listen(
		'updateUserGroupDeviceGroups/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_USER_GROUP_DEVICE_GROUPS`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on update user group device groups');
			}
		}
	)
	.listen(
		'deleteUserGroup/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `DELETE_USER_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on delete user group');
			}
		}
	)
	.listen(
		'createDeviceGroup/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `INSERT_NEW_DEVICE_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on create device group');
			}
		}
	)
	.listen(
		'updateDeviceGroupInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_DEVICE_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on edit device group');
			}
		}
	)
	.listen(
		'updateDeviceGroupDevices/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			const userRole = store.getState().accountState.user.roles;
			const userDeviceGroups = store.getState().accountState.user.deviceGroups;
			const devices = store.getState().deviceState;
			const isDeviceGroupExistForUser = userDeviceGroups[data.deviceGroupId];
			if (status === 'success') {
				if (isDeviceGroupExistForUser) {
					// here add group to device
					if (data.addedDevicesIds.length > 0) {
						data.addedDevicesIds.map(async id => {
							let deviceDetail = devices.items.filter(dev => dev.deviceId == id);
							const payload = {
								deviceId: id,
								props: deviceDetail[0],
								organizationId: data.orgId,
								userRole,
							};
							await store.dispatch({
								type: `ADD_DEVICE_PROPS`,
								payload: payload,
							});
						});
					}
					// here remove group from device
					if (data.removedDevicesIds.length > 0) {
						const payload = {
							deviceId: data.removedDevicesIds[0],
						};
						store.dispatch({
							type: `DELETE_DEVICE_PROPS`,
							payload: payload,
						});
					}
				}
				// store.dispatch({
				// 	type: `UPDATE_DEVICE_GROUP_DEVICES`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on edit device group');
			}
		}
	)
	.listen(
		'deleteDeviceGroup/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `DELETE_DEVICE_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on edit device group');
			}
		}
	)
	.listen(
		'updateOrgBasicInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;

			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_DEVICE_GROUP_DEVICES`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on edit device group');
			}
		}
	)
	// .listen('savedRules', ({ message, params, topic, originalTopic, mqttClient }) => {
	// 	const parsedData = JSON.parse(message);
	// 	let role = store.getState().rolesState.activeRole;

	// 	store.dispatch({
	// 		type: `UPDATE_SEL_ORG_SAVED_USER_ROLES`,
	// 		payload: parsedData,
	// 		organizationType: role.organizationType,
	// 	});

	// 	store.dispatch({
	// 		type: 'ROLES_FETCHED',
	// 		payload: {},
	// 	});
	// })
	// .listen('savedRuleByName', ({ message, params, topic, originalTopic, mqttClient }) => {
	// 	const parsedData = JSON.parse(message);

	// 	store.dispatch({
	// 		type: `INITIALIZE_USER_ROLES`,
	// 		payload: parsedData,
	// 	});

	// 	store.dispatch({
	// 		type: 'ROLE_NAMES_FETCHED',
	// 		payload: {},
	// 	});
	// })
	.listen(
		'getAgreementsContent/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `UPDATE_AGREEMENTS`,
				payload: data,
			});
		}
	)
	.listen(
		'getAcceptedAgreements/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `UPDATE_ACCEPTED_AGREEMENTS`,
				payload: data,
			});
		}
	)
	.listen(
		'getOrgAcceptedAgreements/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;

			store.dispatch({
				type: `UPDATE_ORG_ACCEPTED_AGREEMENTS`,
				payload: data,
			});
		}
	)
	.listen(
		'getOrgSSOInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `FETCH_ORGANIZATION_SSO_CONFIGURATION`,
				payload: data,
			});
		}
	)
	.listen(
		'updateOrgSSOSandboxInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			window.dispatchEvent(
				new CustomEvent('updateOrgSSOSandboxInfo', {
					detail: {
						status: status,
						message:
							status == 'success'
								? 'SSO sandbox mode configuration has been saved successfully.'
								: 'There is some error in processing your request. Please try again!',
						data: data,
					},
				})
			);
			if (!data) return;
			store.dispatch({
				type: `UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION`,
				payload: data,
			});
		}
	)
	.listen(
		'addSSODomainToSandbox/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			console.log('addSSODomainToSandbox data: ', data);
			window.dispatchEvent(
				new CustomEvent('addSSODomainToSandbox', {
					detail: {
						status: data?.result || status,
						message: data?.message
							? data.message
							: status == 'success'
							? 'SSO sandbox mode configuration has been saved successfully.'
							: 'There is some error in processing your request. Please try again!',
					},
				})
			);
			if (
				(data.result && data.result === 'success') ||
				(!data.result && status === 'success')
			) {
				store.dispatch({
					type: `UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION`,
					payload: data,
				});
			}
		}
	)
	.listen(
		'removeSSODomainToSandbox/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			console.log('removeSSODomainToSandbox data: ', data);
			window.dispatchEvent(
				new CustomEvent('removeSSODomainToSandbox', {
					detail: {
						status: status,
						message:
							status == 'success'
								? 'SSO sandbox mode configuration has been saved successfully.'
								: 'There is some error in processing your request. Please try again!',
					},
				})
			);
			if (!data) return;
			store.dispatch({
				type: `UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION`,
				payload: data,
			});
		}
	)
	.listen(
		'applySSOSandboxToLive/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			//if (!data) return;

			if (data) {
				store.dispatch({
					type: `UPDATE_ORGANIZATION_SSO_LIVE_CONFIGURATION`,
					payload: data,
				});
			}
			window.dispatchEvent(
				new CustomEvent('applySSOSandboxToLive', {
					detail: {
						status: status,
						message:
							status == 'success'
								? 'SSO sandbox mode configuration has been applied to live mode successfully.'
								: 'There is some error in processing your request. Please try again!',
					},
				})
			);
		}
	)
	.listen(
		'verifyDomain/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			window.dispatchEvent(
				new CustomEvent('verifyDomain', {
					detail: {
						status: data?.result || status,
						domain: data.domain,
					},
				})
			);
			if (
				(data.result && data.result === 'success') ||
				(!data.result && status === 'success')
			) {
				store.dispatch({
					type: `UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION`,
					payload: data.data,
				});
			}
		}
	)
	.listen(
		'getOrgStructure/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;

			store.dispatch({
				type: `GET_ORGANIZATION_STRUCTURE`,
				payload: data,
			});
		}
	)
	.listen(
		'registerDevice/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			window.dispatchEvent(
				new CustomEvent('deviceCreate', {
					detail: {
						status: data?.result || status,
						domain: data.domain,
					},
				})
			);

			if (
				(data.result && data.result === 'success') ||
				(!data.result && status === 'success')
			) {
				store.dispatch({
					type: `INSERT_NEW_DEVICE_SUCCESS`,
					payload: { newDevice: data },
				});
			}
		}
	)
	.listen(
		'deleteOrg/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			console.log('deleteOrg/response::', data);
			if (!data) return;
			store.dispatch({
				type: `DELETE_ORGANIZATION`,
				payload: data,
			});
		}
	)
	.listen(
		'acceptAgreements/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			const spinoutType = store.getState().versionState.spinoutType;
			const encodedUser = b64EncodeUnicode(store.getState().accountState.user.username);
			publish(`microservice/${encodedUser}/getAcceptedAgreements`, {
				requestId: 'getAcceptedAgreementsId',
				data: {
					spinoutType: spinoutType ? spinoutType : '',
				},
			});
		}
	);

export default AccountRouter;
