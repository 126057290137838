import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './AccessHoursModal.module.css';

import { Button, Grid, InputLabel, styled, Typography } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import moment from 'moment-timezone';
import { createEvent } from 'ics';
import { useDispatch, useSelector } from 'react-redux';
import { addAccessHours } from '../../actions/accessHoursActions';

interface AccessHoursFormProps {
	onSubmit: (data: any) => void;
	onCancel: () => void;
	robotId: string;
}

const AccessHoursForm: FC<AccessHoursFormProps> = (props: any) => {
	const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
	const timezone = moment.tz.guess();
	const [formValues, setFormValues] = useState<any>({
		startTime: moment()
			.tz(timezone)
			.set({ hour: 8, minute: 0, second: 0 }),
		endTime: moment()
			.tz(timezone)
			.set({ hour: 16, minute: 0, second: 0 }),
		days: weekdays.map((day, index) => ({
			day: day,
			checked: true,
		})),
	});

	const { handleSubmit, control, errors, formState, register } = useForm();

	const onCheckboxChange = (index: number) => {
		const newDays = formValues.days.map((day: any, idx: number) =>
			idx === index ? { ...day, checked: !day.checked } : day
		);
		setFormValues((prevValues: any) => ({ ...prevValues, days: newDays }));
		checkDaysSelected();
	};

	const dispatch = useDispatch();
	const onFormSubmit = handleSubmit(() => {
		if (noDaysSelected) {
			return;
		}

		console.log('Submitted form: ', formValues);

		const selectedWeekdays = formValues.days
			.filter((day: any) => day.checked)
			.map((day: any) => {
				switch (day.day) {
					case 'Mon':
						return 'MO';
					case 'Tue':
						return 'TU';
					case 'Wed':
						return 'WE';
					case 'Thu':
						return 'TH';
					case 'Fri':
						return 'FR';
					case 'Sat':
						return 'SA';
					case 'Sun':
						return 'SU';
					default:
						return '';
				}
			})
			.join(',');

		let event: any;

		if (allDaySelected) {
			// Create event for entire day
			event = {
				start: [
					moment().year(),
					moment().month() + 1, // months are 0-based
					moment().date(),
					0,
					0,
					0,
				],
				end: [
					moment().year(),
					moment().month() + 1, // months are 0-based
					moment().date(),
					23,
					59,
					59,
				],
				title: 'Access Hours',
				description: 'Scheduled robot access hours',
				recurrenceRule: `FREQ=WEEKLY;BYDAY=${selectedWeekdays}`,
			};
		} else {
			const startTime = formValues.startTime.utc().format();
			const endTime = formValues.endTime.utc().format();

			if (moment(endTime).isBefore(moment(startTime))) {
				// Create multi-day event if end time is before start time
				event = {
					start: [
						moment(startTime).year(),
						moment(startTime).month() + 1, // months are 0-based
						moment(startTime).date(),
						moment(startTime).hours(),
						moment(startTime).minutes(),
						0,
					],
					end: [
						moment(endTime)
							.add(1, 'day')
							.year(),
						moment(endTime)
							.add(1, 'day')
							.month() + 1, // months are 0-based
						moment(endTime)
							.add(1, 'day')
							.date(),
						moment(endTime).hours(),
						moment(endTime).minutes(),
						59,
					],
					title: 'Access Hours',
					description: 'Scheduled robot access hours (spans multiple days)',
					recurrenceRule: `FREQ=WEEKLY;BYDAY=${selectedWeekdays}`,
				};
			} else {
				event = {
					start: [
						moment(startTime).year(),
						moment(startTime).month() + 1, // months are 0-based
						moment(startTime).date(),
						moment(startTime).hours(),
						moment(startTime).minutes(),
						0,
					],
					end: [
						moment(endTime).year(),
						moment(endTime).month() + 1, // months are 0-based
						moment(endTime).date(),
						moment(endTime).hours(),
						moment(endTime).minutes(),
						59,
					],
					title: 'Access Hours',
					description: 'Scheduled robot access hours',
					recurrenceRule: `FREQ=WEEKLY;BYDAY=${selectedWeekdays}`,
				};
			}
		}

		// Create ics string
		createEvent(event, (error, value) => {
			if (error) {
				console.log(error);
				return;
			}

			// We want ics events only
			const lines = value.split('\n');
			value = lines.slice(6, lines.length - 2).join('\n');

			// Dispatch the ics string to the Redux store
			dispatch(addAccessHours(props.robotId, value));
		});

		props.onCancel();
	});

	const getCustomStyle = useCallback(() => {
		const color = getComputedStyle(document.documentElement).getPropertyValue(
			'--ion-color-primary'
		);
		return {
			'& label.Mui-focused': {
				color: color,
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: color,
			},
			'& .MuiOutlinedInput-root': {
				'&:hover fieldset': {
					borderColor: color,
				},
				'&.Mui-focused fieldset': {
					borderColor: color,
				},
			},
		};
	}, []);

	const CustomTimePicker = useMemo(
		() =>
			styled(TimePicker)(() => {
				return getCustomStyle();
			}),
		[]
	);

	const checkDaysSelected = useCallback(() => {
		const anyDaySelected = formValues.days.some((day: any) => day.checked);
		setNoDaysSelected(!anyDaySelected);
	}, [formValues.days]);

	useEffect(() => {
		checkDaysSelected();
	}, [formValues.days, checkDaysSelected]);

	const [allDaySelected, setAllDaySelected] = useState(true);
	const [multiDayEvent, setMultiDayEvent] = useState(false);
	const [noDaysSelected, setNoDaysSelected] = useState(false);

	const handleTimeChange = (startOrEnd: 'startTime' | 'endTime', date: MaterialUiPickersDate) => {
		setFormValues((prevValues: any) => {
			const newValues = { ...prevValues, [startOrEnd]: date };
			setMultiDayEvent(moment(newValues.endTime).isBefore(moment(newValues.startTime)));
			return newValues;
		});
	};

	return (
		<form onSubmit={onFormSubmit} className={classes.addForm}>
			<Typography className={classes.inputLabel} variant="body1">
				Available days
			</Typography>
			<Grid container spacing={4}>
				{weekdays.map((day, index) => (
					<Grid item xs={3} key={index}>
						<IonItem>
							<IonLabel>{day}</IonLabel>
							<IonCheckbox
								slot="start"
								checked={formValues.days[index].checked}
								onIonChange={() => onCheckboxChange(index)}
							/>
						</IonItem>
					</Grid>
				))}
				<Grid item xs={12}>
					<InputLabel htmlFor="start-date-picker">
						<Typography className={classes.inputLabel} variant="body1">
							Time
						</Typography>
					</InputLabel>
				</Grid>
				<Grid item xs={4}>
					<IonItem>
						<IonLabel>All day</IonLabel>
						<IonCheckbox
							slot="start"
							checked={allDaySelected}
							onClick={() => setAllDaySelected(!allDaySelected)}
						/>
					</IonItem>
				</Grid>
				{!allDaySelected && (
					<>
						<Grid item xs={4}>
							<CustomTimePicker
								clearable
								label="Start time"
								value={formValues.startTime}
								onChange={(date: MaterialUiPickersDate) =>
									handleTimeChange('startTime', date)
								}
								inputVariant="outlined"
								format="HH:mm"
								name="startTime"
								required
								helperText={`Your local time (${moment.tz.guess()}, UTC${moment
									.tz(moment.tz.guess())
									.format('Z')})`}
								data-cy="access_hours_start_time_picker"
							/>
						</Grid>
						<Grid item xs={4}>
							<CustomTimePicker
								clearable
								label="End time"
								value={formValues.endTime}
								onChange={(date: MaterialUiPickersDate) =>
									handleTimeChange('endTime', date)
								}
								inputVariant="outlined"
								format="HH:mm"
								name="EndTime"
								required
								helperText=""
								data-cy="access_hours_end_time_picker"
							/>
						</Grid>
						{multiDayEvent && (
							<Grid item xs={12}>
								<Typography color="secondary">
									The timeslot chosen crosses midnight
								</Typography>
							</Grid>
						)}
					</>
				)}
			</Grid>
			{noDaysSelected && (
				<Grid item xs={12}>
					<Typography color="error">Please select at least one day.</Typography>
				</Grid>
			)}

			<Grid item xs={12} container justifyContent="flex-end">
				<Button type="submit" variant="contained" color="primary" disabled={noDaysSelected}>
					Confirm
				</Button>
			</Grid>
		</form>
	);
};

export default injectIntl(isAuthenticated(AccessHoursForm, 'AccessHoursForm'));
