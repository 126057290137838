import React from 'react';
import { IDeviceCurrentState, Reservation } from '../../../../../../utils/statusConverter';
import { DefaultStatusLabel } from './DefaultStatusLabel';
import '../../index.scss';
import { ReservationStatusLabel } from './ReservationStatusLabel';
import { ReservationWithPopover } from '../../../../CardView/LaunchCard/components/LaunchCardBody/Header/ReservationWithPopover';
import { AccessHoursWithPopover } from '../../../../CardView/LaunchCard/components/LaunchCardBody/Header/AccessHoursWithPopover';

interface Props {
	nextReservation: Reservation;
	othersNextReservation: Reservation;
	myNextReservation: Reservation;
	deviceStatus: IDeviceCurrentState;
	isCalling: boolean;
	isPermanentDevice: boolean;
	deviceSerial: string;
}

export const StatusColumn = ({
	nextReservation,
	othersNextReservation,
	myNextReservation,
	deviceStatus,
	isPermanentDevice,
	isCalling,
	deviceSerial,
}: Props) => {
	return (
		<div className="rosterListRowCell">
			<div className="rosterListStatusCell">
				{!!nextReservation ? (
					<>
						<ReservationStatusLabel
							reservation={nextReservation}
							deviceStatus={deviceStatus}
							isPermanentDevice={isPermanentDevice}
							isCalling={isCalling}
						/>
						<ReservationWithPopover
							othersNextReservation={othersNextReservation}
							myNextReservation={myNextReservation}
							isPermanentDevice={isPermanentDevice}
						/>
					</>
				) : (
					<DefaultStatusLabel status={deviceStatus} isGuest={false} />
				)}
				<AccessHoursWithPopover deviceSerial={deviceSerial} />
			</div>
		</div>
	);
};
