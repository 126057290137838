export const addAccessHours = (robotId: string, value: string) => ({
	type: 'ADD_ACCESS_HOURS',
	payload: { [robotId]: value },
});
export const deleteAccessHours = (robotId: string) => ({
	type: 'DELETE_ACCESS_HOURS',
	payload: robotId,
});
export const deleteAllAccessHours = () => ({
	type: 'DELETE_ALL_ACCESS_HOURS',
});
