import classNames from 'classnames';
import _, { debounce } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState, useTypedSelector } from '../../../../../reducers';
import { Account } from '../../../../../reducers/accountReducers';
import { Device, DeviceGroup } from '../../../../../types/types';
import { b64EncodeUnicode } from '../../../../../utils/encoding';
import { setParameter } from '../../actions/setParam';
import {
	SET_ROASTER_LARGE_ICONS,
	SET_SEARCH_TERM,
	SET_SESSION_INFO,
	SET_SORT_METHOD,
	TOGGLE_SORT_DIRECTION,
} from '../../actions/types';
import { SortMethod } from '../../reducers/roasterReducers';
import {
	getDeviceCurrentState,
	IDeviceCurrentState,
	Reservation,
} from '../../utils/statusConverter';
import { publish } from './../../../../../actions/publish';
import './index.scss';
import SafetyAgreements from './safetyAgreemnets';
import useFilteredRosterDevicesIds from './useFilteredRosterDevicesIds';

import { IClientOptions } from 'mqtt';
import { subscribedDevices, subscribeToDevice } from '../../../../../providers/mqtt';
import { store } from '../../../../../store/store';
import Pagination from '../../components/pagination';
import { CloudLogLevel, SessionInfoProtocol } from '../../types';
import useSessionInitiator from '../session/peerConnection/useSessionInitiator';
import { CardView } from './CardView';
import { ListView } from './ListView';
import SessionPopupBlockedModal from './sessionPopupBlockedModal/sessionPopupBlockedModal';

type Modal = 'SessionPopupBlocked' | 'SafetyAgreement';

const GET_OWN_ACCOUNT_INFO_COOLDOWN_MS = 15 * 1000;
const reduxConnector = connect(
	(state: AppRootState) => {
		const sort = {
			isSortedAscending: state.goBeState.roasterState.isSortedAscending as boolean,
			sortMethod: state.goBeState.roasterState.sortMethod as SortMethod,
		};

		return {
			searchTerm: state.goBeState.roasterState.searchTerm as string,
			sort,
			isGridView: state.goBeState.roasterState.isGridView as boolean,
			accountState: state.accountState,
			agreements: state.accountState.user.agreements,
			acceptedAgreements: state.accountState.user.acceptedAgreements as { type: string }[],
			username: state.accountState.user.username,
			mqttConfig: state.mqttState.mqttConfig as Partial<IClientOptions>,
			localVoiceVolume: state.goBeState.sessionState.localVoiceVolume,
			/** If these related data are loading, we wont apply sort/search */
			isRelatedDataLoading: state.deviceGroupsState.loading || state.deviceState.loading,
			showLoader: state.fetchDataState.showLoader,
		};
	},
	{ setParameter }
	// undefined,
	// { pure: true }
);

const deviceGroupsNamesByIdSelector = (state: AppRootState) =>
	(state.deviceGroupsState.items as DeviceGroup[]).reduce(
		(acc, group) => ({ ...acc, [group.deviceGroupId]: group.name }),
		{} as Record<string, string>
	);

const userDevicesByIdSelector = (state: AppRootState) => {
	return ((state.accountState.user as Account).devices as unknown) as Record<
		string,
		Device & { currentState: IDeviceCurrentState }
	>;
};

const devicesCurrentStateByIdSelector = (state: AppRootState) => {
	const devices: any = state.accountState.user.devices
		? Object.values(state.accountState.user.devices)
		: state.deviceState.items && state.deviceState.items.length
		? state.deviceState.items
		: [];

	return (devices as Device[]).reduce(
		(acc, device) => ({
			...acc,
			[device.serialNumber ?? device.deviceId]: {
				online: device.online,
				currentState: getDeviceCurrentState(device).deviceStatus,
			},
		}),
		{} as Record<string, { online: boolean; currentState: IDeviceCurrentState }>
	);
};

const devicesByFavouriteSelector = (state: AppRootState) => {
	let favouriteDevice = state.accountState.user?.favouriteDevice || [];
	let isFavourite: any = {};

	let devices: any = state.accountState.user.devices
		? Object.values(state.accountState.user.devices).map((device: any) => ({
				deviceId: device.serialNumber,
		  }))
		: state.deviceState.items && state.deviceState.items.length
		? state.deviceState.items
		: [];

	devices.map((item: any) => {
		if (favouriteDevice?.includes(item.deviceId)) {
			item.isFav = 1;
		} else {
			item.isFav = 0;
		}
		isFavourite[item.deviceId] = item.isFav;
	});

	return isFavourite;
};

const orgIdPerDeviceIdSelector = (state: AppRootState) => {
	const userDevicesById = (state.accountState.user as Account).devices;
	const devicesById = (state.deviceState.items as Device[]).reduce(
		(acc, device) => ({
			...acc,
			[device.deviceId]: device,
		}),
		{} as Record<string, Device>
	);
	return Object.keys(userDevicesById).reduce((acc, deviceId) => {
		const orgId = userDevicesById[deviceId]?.orgId ?? devicesById[deviceId]?.orgId;
		if (!orgId) {
			console.error(`RoasterPage: Encountered device: ${deviceId} without orgId!?`);
			return acc;
		}
		return { ...acc, [deviceId]: orgId };
	}, {} as Record<string, string>);
};

const userDevicesIdsSelector = (state: AppRootState) =>
	Object.keys((state.accountState.user as Account).devices);

let sessionConfig: Record<string, string> | null = null;
async function getGoBeSessionAppRedirectUrl(version: String | null = null): Promise<string> {
	// fetch and cache the config
	if (sessionConfig === null) {
		const response = await fetch('/conf/gobeSessionAppConfig.json');
		sessionConfig = await response.json();
		if (sessionConfig === null) {
			throw new Error('Failed to get session app config');
		}
		if (process.env.REACT_APP_IP_OVERRIDE) {
			console.log('Using IP_OVERRIDE', process.env.REACT_APP_IP_OVERRIDE);
			sessionConfig = {
				hostDevelopment: 'https://' + process.env.REACT_APP_IP_OVERRIDE + ':3001',
				hostDevelopmentV3: 'https://' + process.env.REACT_APP_IP_OVERRIDE + ':3001',
				...sessionConfig,
			};
		} else {
			sessionConfig = {
				hostDevelopment: 'https://localhost:3001',
				hostDevelopmentV3: 'https://localhost:3001',
				...sessionConfig,
			};
		}
	}

	// In development, we dont care about the REACT_APP_FM value;
	//		we will always use localhost (this is what the Development config points to)
	const env =
		process.env.NODE_ENV === 'development' ? 'Development' : process.env.REACT_APP_FM || '';

	const url = sessionConfig[`host${env}${version ? String(version).toUpperCase() : ''}`];
	if (!url) {
		throw new Error('Failed to get session app config');
	}

	return url;
}

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type PreferredUserMediaDevices = {
	camera: { name?: string } | null;
	microphone: { name?: string } | null;
	speakers: { name?: string } | null;
	microphoneVolume: number;
	speakersVolume: number;
};

const Roaster: React.FC<PropsFromRedux> = ({
	searchTerm,
	sort,
	isGridView,
	setParameter,
	accountState,
	agreements,
	acceptedAgreements,
	username,
	mqttConfig,
	localVoiceVolume,
	isRelatedDataLoading,
	showLoader,
}) => {
	const userDevicesById = useTypedSelector(userDevicesByIdSelector, _.isEqual);
	const devicesByFavourite = useTypedSelector(devicesByFavouriteSelector, _.isEqual);
	const devicesCurrentStateById = useTypedSelector(devicesCurrentStateByIdSelector, _.isEqual);
	const deviceGroupsNamesById = useTypedSelector(deviceGroupsNamesByIdSelector, _.isEqual);
	const orgIdPerDeviceId = useTypedSelector(orgIdPerDeviceIdSelector, _.isEqual);
	const userDevicesIds = useTypedSelector(userDevicesIdsSelector, _.isEqual);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	const hardwareState = useTypedSelector(
		state => ((state.hardwareState as any).settings as unknown) as PreferredUserMediaDevices
	);
	const preferredUserMediaDevices: PreferredUserMediaDevices = useMemo(() => {
		const { camera, microphone, speakers, microphoneVolume, speakersVolume } = hardwareState;
		return {
			camera:
				camera ??
				JSON.parse(localStorage.getItem('preferredMediaDevices.camera') ?? 'null'),
			microphone:
				microphone ??
				JSON.parse(localStorage.getItem('preferredMediaDevices.microphone') ?? 'null'),
			speakers:
				speakers ??
				JSON.parse(localStorage.getItem('preferredMediaDevices.speakers') ?? 'null'),
			microphoneVolume:
				microphoneVolume ??
				JSON.parse(
					localStorage.getItem('preferredMediaDevices.microphoneVolume') ?? 'null'
				),
			speakersVolume:
				speakersVolume ??
				JSON.parse(localStorage.getItem('preferredMediaDevices.speakersVolume') ?? 'null'),
		};
	}, [hardwareState]);

	const roasterRef = useRef<HTMLDivElement | null>(null);

	const { preSessionState, initiateSession } = useSessionInitiator({
		mqttConfig,
		credentials: {
			username: accountState.user.username,
			password: accountState.user.password,
		},
		pilot: {
			avatar: accountState.user.profilePictureLink ?? '',
			name: `${accountState.user.firstName} ${accountState.user.lastName}`,
			id: accountState.user.username,
		},
		settings: {
			initialVolume: localVoiceVolume ? Number.parseFloat(localVoiceVolume) : undefined,
		},
	});

	const filteredDevicesIds = useFilteredRosterDevicesIds({
		sort,
		searchTerm,
		userDevicesById,
		devicesByFavourite,
		devicesCurrentStateById,
		deviceGroupsNamesById,
		userDevicesIds,
		isRelatedDataLoading,
		isInPreSessionState: preSessionState != null,
	});

	// Pagination
	const [pageSize, setPageSize] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const paginatedDevicesIdsRef = useRef<typeof filteredDevicesIds>(
		filteredDevicesIds.slice((currentPage - 1) * pageSize, currentPage * pageSize)
	);
	const wrappedClient = store.getState().mqttState.client;

	useMemo(() => {
		const paginatedDevicesIds = filteredDevicesIds.slice(
			(currentPage - 1) * pageSize,
			currentPage * pageSize
		);
		if (
			wrappedClient &&
			wrappedClient.listen &&
			wrappedClient.unlisten &&
			paginatedDevicesIds.join(',') !== paginatedDevicesIdsRef.current.join(',')
		) {
			paginatedDevicesIdsRef.current = paginatedDevicesIds;
			paginatedDevicesIdsRef.current.forEach((deviceId: string) => {
				if (!(subscribedDevices as { [id: string]: boolean })[deviceId]) {
					subscribeToDevice(wrappedClient, deviceId);
					(subscribedDevices as { [id: string]: boolean })[deviceId] = true;
				}
			});
		}

		return { currentPage, pageSize, filteredDevicesIds };
	}, [currentPage, pageSize, filteredDevicesIds]);

	const handleWindowMessage = useCallback(event => {
		if (event?.data?.refreshPage) {
			window.location.reload();
		}
	}, []);

	const getOwnAccountInfoTimestamp = store.getState().fetchDataState.getOwnAccountInfoTimestamp;

	const nowRef = useRef<number>(+new Date());

	useEffect(() => {
		if (
			!getOwnAccountInfoTimestamp ||
			nowRef.current > getOwnAccountInfoTimestamp + GET_OWN_ACCOUNT_INFO_COOLDOWN_MS
		)
			publish(
				`microservice/${b64EncodeUnicode(accountState.user.username)}/getOwnAccountInfo`,
				JSON.stringify({ requestId: 'someId' })
			);
	}, [nowRef.current]);

	useEffect(() => {
		getGoBeSessionAppRedirectUrl().catch(error =>
			console.error('Error caching redirect-url', error)
		);
		window.addEventListener('message', handleWindowMessage, false);
		return () => {
			window.removeEventListener('message', handleWindowMessage);
		};
	}, []);

	/** Publish to the cloud that user has accepted safety agreement */
	const publishSafetyAgreementAcceptance = async () => {
		await publish(
			`microservice/${accountState.user.selectedOrganizationId}/${b64EncodeUnicode(
				accountState.user.username
			)}/acceptAgreements/user`,
			{
				requestId: 'acceptAgreementsId',
				data: {
					language: 'en',
					ip_address: undefined,
					agreements_ids: [
						agreements.find((agreement: any) => agreement.type === 'safety-agreement')
							.id,
					],
					spinout_type: spinoutType ? spinoutType : '',
				},
			}
		);
	};

	/** Publish to the cloud that user has accepted safety agreement */
	const publishFavouriteDevice = async (device: Device) => {
		let isExist = accountState.user.favouriteDevice?.includes(device.serialNumber) || false;
		publish(
			`microservice/${b64EncodeUnicode(accountState.user.username)}/addDeviceToFavourite`,
			{
				requestId: 'addDeviceToFavourite',
				data: {
					deviceId: device.serialNumber,
					type: isExist ? 'remove' : 'add',
				},
			}
		);
	};

	useEffect(() => {
		publish(`microservice/${b64EncodeUnicode(username)}/getAcceptedAgreements`, {
			requestId: 'getAcceptedAgreementsId',
			data: {
				spinoutType: spinoutType ? spinoutType : '',
			},
		});
	}, [username]);

	useEffect(() => {
		let link = document.getElementById('jsd-widget');
		if (link && (link as any).style.display === 'none') {
			(link as any).style.display = 'block';
		}
	}, []);

	const [cloudLogLevel, setCloudLogLevel] = useState<CloudLogLevel | null>(null);
	(window as any).enableCloudLog = (level: CloudLogLevel = 'log') => setCloudLogLevel(level);

	const onClickStartSession = (
		device: Device,
		currentReservation?: Reservation,
		othersNextReservation?: Reservation
	) => {
		const startSession = async () => {
			await showSessionAgreement(); // will wait until user has accepted agreement

			const now = +new Date();

			let parsedCurrentReservation: Reservation | undefined;
			let parsedOthersNextReservation: Reservation | undefined;
			if (currentReservation)
				parsedCurrentReservation = {
					...currentReservation,
					startDateOffsetInMs: +new Date(currentReservation.startDate) - now,
					endDateOffsetInMs: +new Date(currentReservation.endDate) - now,
					durationInMs:
						+new Date(currentReservation.endDate) -
						+new Date(currentReservation.startDate),
				};
			if (othersNextReservation)
				parsedOthersNextReservation = {
					...othersNextReservation,
					startDateOffsetInMs: +new Date(othersNextReservation.startDate) - now,
					endDateOffsetInMs: +new Date(othersNextReservation.endDate) - now,
					durationInMs:
						+new Date(othersNextReservation.endDate) -
						+new Date(othersNextReservation.startDate),
				};

			const { sessionInfo, abortSession } = await initiateSession(
				{
					serialNumber: device.serialNumber || device.deviceId,
					name: device.name,
				},
				{
					isMyPermanentDevice: device.isPermanent!,
					currentReservation: parsedCurrentReservation,
					othersNextReservation: parsedOthersNextReservation,
				}
			);

			const robotStatus = device.status
				? JSON.parse(device.status)
				: {
						battery: device.battery || { level: 100, charging: false },
						network: device.network || { quality: 100, ssid: '' },
				  };
			robotStatus.battery.level = robotStatus.battery.level.toString();

			setParameter('sessionInfo', SET_SESSION_INFO, sessionInfo);

			let redirectUrl = await getGoBeSessionAppRedirectUrl(sessionInfo.protocol);

			let blobData: any = {};

			switch (sessionInfo.protocol) {
				case SessionInfoProtocol.V3:
					blobData = {
						capabilities: sessionInfo.capabilities,
					};
					break;
				default:
					blobData = {
						robotStatus,
					};
					break;
			}

			blobData = {
				...blobData,
				..._.omit(sessionInfo, 'capabilities', 'mqttConfig'),
				...(currentReservation || othersNextReservation
					? {
							reservation: {
								isMyPermanentDevice: device.isPermanent,
								currentReservation: parsedCurrentReservation,
								othersNextReservation: parsedOthersNextReservation,
							},
					  }
					: {}),
				devices: {
					// we dont send the ids, because getUserMedia returns different device ids for different tabs
					camera: _.omit(preferredUserMediaDevices.camera, 'id'),
					microphone: _.omit(preferredUserMediaDevices.microphone, 'id'),
					speaker: _.omit(preferredUserMediaDevices.speakers, 'id'),
					microphoneVolume: preferredUserMediaDevices.microphoneVolume,
					speakersVolume: preferredUserMediaDevices.speakersVolume,
				},
				...(cloudLogLevel ? { cloudLogLevel } : {}),
			};

			const blob = JSON.stringify(blobData);

			const encodedSessionInfo = btoa(encodeURIComponent(blob));

			const openSessionTab = () =>
				!!window.open(`${redirectUrl}/?sessionInfo=${encodedSessionInfo}`, '_blank');

			const isPopupBlocked = !openSessionTab();
			if (isPopupBlocked) {
				await showSessionPopupBlockedModal()
					.then(openSessionTab)
					.catch(error => {
						abortSession();
						throw error;
					});
			}
		};

		startSession()
			.then(() => setCloudLogLevel(null))
			.catch(error => {
				// todo: Show a proper error indicator UI
				console.error('Error initiating session: ', error);
			});
	};

	const isSafetyAgreementAccepted = acceptedAgreements.some(
		(agreement: any) => agreement.type === 'safety-agreement'
	);
	const [agreementModalActions, setAgreementModalActions] = useState<{
		onAccept: () => void;
		onReject: () => void;
	}>();
	/** Returns a promise that resolves/rejects when user accepts safety agreements */
	const showSessionAgreement = async () => {
		if (isSafetyAgreementAccepted || agreements.length === 0) return Promise.resolve();

		await new Promise<void>((resolve, reject) => {
			/** Called to mark acceptance/cancellation of safety agreement */
			const onComplete = (error: unknown = null) => {
				setCurrentModal(null);
				setAgreementModalActions(undefined);

				if (error) reject(error);
				else resolve();
			};

			/** Called when the user accepts the safety agreement */
			const onAccept = () => {
				publishSafetyAgreementAcceptance()
					.then(() => onComplete())
					.catch(error => onComplete(error));
			};

			/** Called when the user rejects/cancels the safety agreement */
			const onReject = () => {
				onComplete(new Error('User rejected safety agreement'));
			};

			// show the agreement modal, and then we wait for the user to accept or reject
			setAgreementModalActions({ onAccept, onReject });
			setCurrentModal('SafetyAgreement');
		});
	};
	const deviceGroupsNamesPerDeviceId = useTypedSelector(
		deviceGroupsNamesPerDeviceIdSelector,
		_.isEqual
	);

	const renderRosterView = () => {
		const RosterViewComponent = isGridView ? CardView : ListView;

		return (
			<RosterViewComponent
				isLoading={paginatedDevicesIdsRef.current.length || showLoader}
				devicesIds={paginatedDevicesIdsRef.current}
				orgIdPerDeviceId={orgIdPerDeviceId}
				preSessionState={preSessionState}
				onClickStartSession={onClickStartSession}
				publishFavouriteDevice={publishFavouriteDevice}
				deviceGroupsNamesPerDeviceId={deviceGroupsNamesPerDeviceId}
			/>
		);
	};

	const onSearch = useCallback(
		(searchTerm: string) => setParameter('searchTerm', SET_SEARCH_TERM, searchTerm),
		[setParameter]
	);

	const onToggleRosterViewType = useCallback(
		() => setParameter('isGridView', SET_ROASTER_LARGE_ICONS, !isGridView),
		[isGridView, setParameter]
	);

	const onChangeSortMethod = useCallback(
		(sortMethod: SortMethod) => setParameter('sortMethod', SET_SORT_METHOD, sortMethod),
		[setParameter]
	);

	const onToggleSortDirection = useCallback(
		// TODO: Implement this with plain dispatch
		() => setParameter('isSortedAscending', TOGGLE_SORT_DIRECTION),
		[setParameter]
	);

	const [currentModal, setCurrentModal] = useState<Modal | null>(null);

	const [popupBlockedModalActions, setPopupBlockedModalActions] = useState<{
		onOkay: () => void;
		onCancel: () => void;
		onTimeout: () => void;
	}>();

	const showSessionPopupBlockedModal = async () => {
		return new Promise<void>((resolve, reject) => {
			const closeModal = () => {
				setCurrentModal(null);
				setPopupBlockedModalActions(undefined);
			};

			setPopupBlockedModalActions({
				onOkay: () => {
					closeModal();
					resolve();
				},
				onCancel: () => {
					closeModal();
					reject(Error('User cancelled `Session Popup Blocked` modal'));
				},
				onTimeout: () => {
					closeModal();
					reject(
						Error(
							"Timed out, waiting for user to click 'Okay' on `Session Popup Blocked` modal"
						)
					);
				},
			});
			setCurrentModal('SessionPopupBlocked');
		});
	};

	const agreement = useMemo(
		() => agreements.find((agreement: any) => agreement.type === 'safety-agreement'),
		[agreements]
	);

	return (
		<div className="roasterContainer" id="roasterContainer" ref={roasterRef}>
			<div className="goBesContainer">
				<div className="goBesShowSetting">
					<div className="goBesTitle">All GoBe Robots</div>
					<div className="searchSettingContainer">
						<DevicesSearchInput value={searchTerm} onChange={onSearch} />
						<RosterViewTypeToggle
							isGridView={isGridView}
							onToggleRosterViewType={onToggleRosterViewType}
						/>
						<SortByDropDown
							config={SortByConfigs}
							sortOptions={sort}
							onSelectSortMethod={onChangeSortMethod}
							onToggleSortDirection={onToggleSortDirection}
						/>
					</div>
				</div>
				<div className="rosterViewContainer">{renderRosterView()}</div>
				<Pagination
					className="pagination-bar"
					currentPage={currentPage}
					totalCount={filteredDevicesIds.length}
					pageSize={pageSize}
					onPageChange={page => {
						setCurrentPage(page);
					}}
				/>
			</div>
			<div className="roasterFooterContainer">
				<div className="borLogoLines" />
				<div className="borLogoWrapper">
					<img src="../assets/images/black-bor-logo.svg" alt="blue-ocean-robotics-logo" />
				</div>
				<div className="borLogoLines" />
			</div>
			{currentModal === 'SessionPopupBlocked' && (
				<SessionPopupBlockedModal {...popupBlockedModalActions} />
			)}
			{agreement ? (
				<SafetyAgreements
					agreement={{
						message: 'Safety Agreements',
						value: '1000',
						description: agreement.content,
					}}
					isSafetyAgreement="safety"
					isOpen={currentModal === 'SafetyAgreement'}
					cancelAgreementClick={
						agreementModalActions ? agreementModalActions.onReject : () => {}
					}
					agreeContinueClick={
						agreementModalActions ? agreementModalActions.onAccept : () => {}
					}
				/>
			) : null}
		</div>
	);
};

export default reduxConnector(Roaster);

const deviceGroupsNamesPerDeviceIdSelector = (state: AppRootState) => {
	const deviceGroupsById = state.deviceGroupsState.items.reduce(
		(acc, deviceGroup) => ({ ...acc, [deviceGroup.deviceGroupId]: deviceGroup }),
		{} as Record<string, DeviceGroup>
	);
	return (state.deviceState.items as Device[]).reduce((acc, device) => {
		return {
			...acc,
			[device.deviceId]: (device.deviceGroupsIds ?? [])
				.map(dGId => deviceGroupsById[dGId]?.name)
				.join(', '),
		};
	}, {} as Record<string, string>);
};

function DevicesSearchInput(props: {
	value?: string | null;
	onChange: (value: string) => void;
	debounce?: number;
}) {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const doSearch = useCallback(
		debounce(
			(searchTerm: string) => {
				props.onChange(searchTerm);
			},
			150,
			{ trailing: true }
		),
		[props.onChange]
	);

	const [_searchTerm, _setSearchTerm] = useState(props.value ?? '');
	useEffect(() => doSearch(_searchTerm), [_searchTerm, doSearch]);

	const clearInput = () => {
		props.onChange(''); // apply the change immediately
		_setSearchTerm('');
	};

	return (
		<div className="searchGoBes">
			<div className="searchIconWrapper">
				<img src="../assets/images/search.svg" alt="" />
			</div>
			<input
				ref={inputRef}
				placeholder="Search robots"
				value={_searchTerm}
				onChange={e => _setSearchTerm(e.target.value)}
			/>
			<div className="searchClearIconButton" onClick={clearInput}>
				<img className="closeIconImage" src="../assets/images/close.svg" alt="" />
			</div>
		</div>
	);
}

function RosterViewTypeToggle(props: { isGridView: boolean; onToggleRosterViewType: () => void }) {
	const { isGridView, onToggleRosterViewType: onClick } = props;
	return (
		<div className="showModelContainer" onClick={onClick}>
			<div className="largeIconWrapper">
				<img
					src={`../assets/images/${!isGridView ? 'largeIcon.svg' : 'listIcon.svg'}`}
					alt=""
				/>
			</div>
			{!isGridView ? 'Card View' : 'List View'}
		</div>
	);
}

const SortByConfigs: { value: SortMethod; label: string; disabled?: boolean }[] = [
	{ value: 'name', label: 'Name' },
	{ value: 'deviceGroup', label: 'Robot Group' },
	{ value: 'availability', label: 'Is Available' },
	{ value: 'isFavourite', label: 'Is Favorite' },
];

function SortByDropDown<T extends string>(props: {
	config: { value: T; label: string; disabled?: boolean }[];
	sortOptions: { sortMethod: T; isSortedAscending: boolean };
	onSelectSortMethod: (sortMethod: T) => void;
	onToggleSortDirection: () => void;
}) {
	const [isDropDownVisible, setIsSortDropDownVisible] = useState(false);
	const toggleDropDown = () => setIsSortDropDownVisible(!isDropDownVisible);

	const dropDownRef = useRef<HTMLDivElement | null>(null);
	useLayoutEffect(() => {
		if (isDropDownVisible) dropDownRef.current?.focus({ preventScroll: true });
	}, [isDropDownVisible]);

	const onClickSortMethod = (sortMethod: T) => {
		toggleDropDown();
		props.onSelectSortMethod(sortMethod);
	};

	return (
		<div
			tabIndex={-1}
			ref={dropDownRef}
			className="sortByContainer"
			onBlur={isDropDownVisible ? toggleDropDown : undefined}
		>
			<div className="sortIcon" onClick={props.onToggleSortDirection}>
				<div
					className={classNames('arrowUpWrapper', {
						arrowUpWrapperRotate: !props.sortOptions.isSortedAscending,
					})}
				>
					<img src="../assets/images/arrow-up.svg" alt="sort-direction-indicator" />
				</div>
			</div>
			<div className="sortText" onClick={toggleDropDown}>
				Sort By
				<div
					className={classNames('chevronUpWrapper', {
						chevronUpWrapperRotate: isDropDownVisible,
					})}
				>
					<img src="../assets/images/chevron-down.svg" alt="dropdown-open-state" />
				</div>
			</div>
			<div className={isDropDownVisible ? 'sortDropDown' : 'sortDropDown sortDropDownHide'}>
				{props.config.map(config => (
					<div
						className={classNames('sortDropDownItem', {
							sortDropDownItemSelected: props.sortOptions.sortMethod === config.value,
							sortDropDownItemDisabled: config.disabled,
						})}
						onClick={
							config.disabled ? undefined : () => onClickSortMethod(config.value)
						}
					>
						{config.label}
					</div>
				))}
			</div>
		</div>
	);
}
