import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { styled } from '@material-ui/core/styles';
import Tooltip from 'react-tooltip-lite';

const TextButton = styled(Button)(({ theme }) => ({
	boxShadow: 'none',
	textTransform: 'none',
	color: 'white',
	paddingLeft: '15px',
	paddingRight: '15px',
	backgroundColor: 'var(--ion-color-primary)',
	whiteSpace: 'nowrap',
	'&:hover': {
		backgroundColor: 'var(--ion-color-secondary)',
	},
}));
const TextButtonIcon = styled(IonIcon)({
	marginRight: '0.5vh',
	fontSize: '1.5em',
});
const CircularProgressIcon = styled(CircularProgress)({
	color: 'white',
	marginRight: '0.5vh',
});

interface RobotButtonProps {
	className: string;
	editable: boolean;
	onClickFunc: () => boolean;
	tooltipText: string;
	icon: any;
	btnText: string;
	operationInProgress: boolean;
}

const RobotButton: React.FC<RobotButtonProps> = ({
	className,
	editable,
	onClickFunc,
	tooltipText,
	icon,
	btnText,
	operationInProgress,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (operationInProgress === false) {
			setIsLoading(false);
		}
	}, [operationInProgress]);

	const handleClick = () => {
		if (operationInProgress) {
			alert('Operation already in progress, please wait for it to finish');
			return;
		}
		if (onClickFunc()) {
			setIsLoading(true);
		}
	};

	return (
		<Tooltip content={tooltipText}>
			<TextButton className={className} onClick={handleClick} disabled={isLoading}>
				{isLoading ? <CircularProgressIcon size={24} /> : <TextButtonIcon icon={icon} />}
				{btnText}
			</TextButton>
		</Tooltip>
	);
};

export default RobotButton;
